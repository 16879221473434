import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

export async function getGSMSponsorshipCSR() {
    const response = await httpGetRequest('/about-sponsorship?populate[sponsorship][populate][image][fields]=url&populate[sponsorship][populate][logo][fields]=url&populate[csr][populate][logo][fields]=url&populate[csr][populate][image][fields]=url&populate[csr][populate][images][populate][image][fields]=url&populate[sponsorship][populate][images][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    const {attributes = {}} = data;
    const {sponsorship,csr} = attributes;
    return {
        sponsorship: {
            name: sponsorship?.name??'Sponsorship',
            detail: sponsorship?.detail??'In an effort to support young talent and build up communities, GSM\n' +
                'Group are the proud sponsors of the Young Africans Sports Club, Tanzania\'s most successful football club with 28 Premier League titles.\n' +
                'We also sponsor Namungo football club, Coastal Union football club, and Singida Big Stars football club.',
            // image: fileUrl(sponsorship?.image?.data?.attributes?.url),
            images: itOrEmptyList(sponsorship.images).map(x=>{
                return {
                    title: x?.title,
                    details: x?.details,
                    image:  fileUrl(x?.image?.data?.attributes?.url),
                }
            }),
            logo: fileUrl(sponsorship?.logo?.data?.attributes?.url)
        },
        csr: {
            name: csr?.name??'CSR',
            detail: csr?.detail??'The GSM Foundation is on a mission to create and support programs that improve the health and education sectors in Tanzania. \n' +
                '\n' +
                'We provide food and care packages to orphanages, make donations to rare disease research and support the building of children’s and maternity hospitals.  \n' +
                '\n' +
                'In 2016, GSM Foundation travelled with a team of doctors and surgeons to every major city in Tanzania and performed over 1,000 surgeries at 99.9% success rate on children with hydrocephalus and spina bifida. In 2017, the foundation built an obstetric theatre at Mwananyamala Hospital in Dar es Salaam.\n',
            // image: fileUrl(csr?.image?.data?.attributes?.url),
            images: itOrEmptyList(csr.images).map(x=>{
                return {
                    title: x?.title,
                    details: x?.details,
                    image:  fileUrl(x?.image?.data?.attributes?.url),
                }
            }),
            logo: fileUrl(csr?.logo?.data?.attributes?.url)
        }
    }
}
