import {AppHeader} from "../../core/components/AppHeader";
import Footer from "../../core/components/Footer";
import NewsUpdates from "../../core/components/NewsUpdates";
import {AboutMenu} from "../components/AboutMenu";
import React, {Fragment, useEffect, useState} from "react";
import {getGSMSponsorshipCSR} from "../services/about_sponsorship";
import {gsmBlackColor, gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {BodyLarge} from "../../core/components/BodyLarge";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Container from "../../core/components/Container";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {useScrollUp} from "../../core/hooks/scrollUp";
import {TitleMedium} from "../../core/components/TitleMedium";
import {BodyMedium} from "../../core/components/BodyMedium";
import {TitleLarge} from "../../core/components/TitleLarge";
import {emailSubscriptionHttpRequest} from "../../core/services/network.mjs";
import {LabelMedium} from "../../core/components/LabelMedium";
import {ScrollDownButton} from "../../core/components/ScollDownButton";
import {LabelLarge} from "../../core/components/LabelLarge";

export function AboutSponsorship() {
    const [selected, setSelected] = useState();
    const [data, setData] = useState({});
    const [showDialog, setDialog] = useState(undefined);

    useEffect(() => {
        getGSMSponsorshipCSR().then(setData).catch(_ => {
        });
    }, []);

    useEffect(() => {
        setSelected(data?.sponsorship);
    }, [data]);
    useScrollUp();

    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <AboutMenu activeLink={'/about_sponsorship'}/>
            <div style={{
                backgroundColor: gsmRedColor,
                height: 100,
                padding: 2,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: isSmallScreen ? 'column' : 'row',
                display: 'flex'
            }}>
                {
                    itOrEmptyList(Object.keys(data ?? {})).map((k, index) => {
                        return (
                            <div style={{cursor: 'pointer', padding: 8}} onClick={() => {
                                setSelected(data[k])
                            }} key={`${index}sponsor_item`}>
                                <HeadlineSmall color={selected === data[k] ? gsmWhiteColor : gsmBlackColor}>
                                    {data[k]?.name}
                                </HeadlineSmall>
                            </div>
                        );
                    })
                }
            </div>
            {
                selected && <Container>
                    <WhiteSpace height={48}/>
                    <div style={{display: "flex", flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center'}}>
                        <div style={{padding: 16, flexGrow: 0.25}}>
                            <img alt={'logo'} style={{width: 100, height: 'auto'}} src={selected?.logo}/>
                        </div>
                        <div style={{flexGrow: 0.75, padding: 16}}>
                            <WhiteSpace width={16}/>
                            <BodyLarge>{selected?.detail}</BodyLarge>
                        </div>
                    </div>
                    <WhiteSpace height={48}/>
                    {
                        selected?.image &&
                        <div style={{width: '100%'}}>
                            <img alt={'Yanga'} style={{width: '100%', borderRadius: 10}} src={selected?.image}/>
                        </div>
                    }
                    {
                        itOrEmptyList(selected?.images).map((item, index) => {
                            return (
                                <Fragment key={`${item?.title}_${index}`}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'nowrap',
                                        overflowX: 'scroll'
                                    }}>
                                        <div onClick={() => {
                                            setDialog(item)
                                        }} className={'sponsorship-hover'} style={{
                                            width: 270,
                                            height: "auto",
                                            borderRadius: 10,
                                            background: "#f5f5f5",
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: 'center',
                                        }}>
                                            <img width={250} height={300} alt={''} src={item?.image}
                                                 style={{borderRadius: 10, objectFit: "cover", marginTop: 10}}/>
                                            <WhiteSpace height={16}/>
                                            <div style={{width: '90%'}}>
                                                <TitleLarge>{item?.title}</TitleLarge>
                                            </div>
                                            <WhiteSpace height={16}/>
                                            <div style={{width: '90%'}}>
                                                <BodyMedium>{item?.details?.substring(0, 50).concat('...')}</BodyMedium>
                                            </div>
                                            <WhiteSpace height={16}/>
                                            <div style={{width: '90%'}}>
                                                <button
                                                    onClick={() => {
                                                        setDialog(item)
                                                    }}
                                                    style={{
                                                        border: 'none',
                                                        backgroundColor: gsmRedColor,
                                                        color: 'white',
                                                        padding: 4,
                                                        fontSize: 16,
                                                        // height: 58,
                                                        cursor: "pointer",
                                                        borderTopRightRadius: 8,
                                                        borderBottomRightRadius: 8
                                                    }}>
                                                    <LabelMedium fontWeight={500}>Lead more</LabelMedium>
                                                </button>
                                            </div>
                                            <WhiteSpace height={16}/>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                </Container>
            }
            <NewsUpdates/>
            <Footer/>
            {!!showDialog && <div onClick={() => {
                setDialog(undefined);
            }} style={{
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: 'rgba(0,0,0,0.6)',
                zIndex: 99999999999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <div style={{
                    maxWidth: '80%',
                    maxHeight: '80%',
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    padding: 16,
                    borderRadius: 10
                }}>
                    <TitleLarge>{showDialog?.title}</TitleLarge>
                    <WhiteSpace height={16}/>
                    <img alt={''} style={{width: 'auto', maxHeight: 400, borderRadius: 10, objectFit: "cover"}}
                         src={showDialog?.image}/>
                    <WhiteSpace height={16}/>
                    <BodyLarge>{showDialog?.details}</BodyLarge>
                    <WhiteSpace height={16}/>
                    <button onClick={() => {
                        setDialog(undefined);
                    }} style={{
                        padding: 8,
                        borderRadius: 4,
                        background: gsmRedColor,
                        border: 'none',
                        backgroundColor: gsmRedColor,
                        color: 'white',
                        fontSize: 16,
                        height: 48,
                        width: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                    }}>
                        <BodyLarge>CLOSE</BodyLarge>
                    </button>
                    <WhiteSpace height={16}/>
                </div>
            </div>}
        </div>
    )
}
