import {BackgroundImage} from "../../core/components/BackgroundImage";
import {HeaderNavItem} from "../../core/components/HeaderNavItem";
import {gsmWhiteColor} from "../../core/services/colors.mjs";
import {WhiteSpace} from "../../core/components/WhiteSpace";

const spaceB = 8;
export function AboutMenu({activeLink}) {
    return (
        <div style={{width: '100%', height: 300}}>
            <BackgroundImage
                radius={0}
                source={'https://cms.gsmgroup.africa/uploads/shutterstock_778252423_a5370402f3.png'}>
                <div style={{width: '100%', display: 'flex', flexDirection: "row", justifyContent: "center", flexWrap: 'wrap'}}>
                    <HeaderNavItem
                        active={activeLink === '/about'}
                        link={'/about'} color={gsmWhiteColor}
                        name={'OUR STORY'}
                    />
                    <WhiteSpace width={spaceB}/>
                    <HeaderNavItem
                        active={activeLink === '/about_us'}
                        link={'/about_us'} color={gsmWhiteColor}
                        name={'ABOUT US'}
                    />

                    <WhiteSpace width={spaceB}/>
                    <HeaderNavItem
                        active={activeLink === '/about_governance'}
                        link={'/about_governance'} color={gsmWhiteColor}
                        name={'GOVERNANCE'}
                    />
                    <WhiteSpace width={spaceB}/>
                    <HeaderNavItem
                        active={activeLink === '/about_sponsorship'}
                        link={'/about_sponsorship'} color={gsmWhiteColor}
                        name={'SPONSORSHIP & CSR'}
                    />
                    <HeaderNavItem
                        active={activeLink === '/about_career'}
                        link={'/about_career'} color={gsmWhiteColor}
                        name={'CAREER'}
                    />
                </div>
            </BackgroundImage>
        </div>
    )
}