import Footer from "../../core/components/Footer";
import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Container from "../../core/components/Container";
import NewsUpdates from "../../core/components/NewsUpdates";
import {HeadItem} from "../components/HeadItem";
import React, {useEffect, useState} from "react";
import {getGSMTrading} from "../services/trading";
import {compose, itOrEmptyList, prepareSplitArrayTo} from "../../core/services/utils.mjs";
import {BodyItem} from "../components/BodyItem";
import {useScrollUp} from "../../core/hooks/scrollUp";

const getProducts = compose(itOrEmptyList, prepareSplitArrayTo(2));

function Item({items, cover = true}) {
    return (
        <>
            {
                itOrEmptyList(items).map((item, index2) => {
                    return (
                        <div style={{flexGrow: 1, margin: 10}}
                             key={`${index2}_inner_product`}>
                            <BodyItem cover={cover} name={item?.name} image={item?.image}/>
                        </div>
                    )
                })
            }
        </>
    );
}

function IBody({products = [], cover = true}) {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (products?.length > 0) {
            setData(getProducts(products));
        }
    }, [products])
    return (
        <>
            {
                itOrEmptyList(data).length > 0 && data.map((x, ix) => {
                    return (
                        <div key={`${ix}_manu_item`}
                             style={{flexDirection: 'row', width: '100%', display: 'flex'}}>
                            <Item cover={cover} items={x}/>
                        </div>
                    )
                })
            }
        </>
    );
}

export function TradingPage() {
    const [data, setData] = useState([]);

    useEffect(() => {
        getGSMTrading()
            .then(compose(setData, itOrEmptyList))
            .catch(() => null);
    }, []);
    useScrollUp()
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <WhiteSpace height={48}/>
            <Container>
                {
                    data.length > 0 && data.map((item, index) => {
                        return (
                            <div key={`${index}_trading_items`}>
                                <HeadItem catalog_link={item?.catalog_link} logo={item?.logo} detail={item?.detail}/>
                                <IBody
                                    cover={!`${item?.detail}`.toLowerCase().includes('haier')}
                                    products={item?.products}
                                />
                                <WhiteSpace height={48}/>
                            </div>
                        )
                    })
                }
            </Container>
            <NewsUpdates/>
            <Footer/>
        </div>
    );
}