import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultData = [
    {
        logo: 'https://ipfs.io/ipfs/bafybeibniarjk7sl5obo2frfjr53v7kioozt6y3oivc3vsqgp5t2lr7ncq/LOGO%20GSM%20TANZANIA-01%20(2).png',
        detail: 'GSM Tanzania is the exclusive importer of Tiger Head batteries from China, and a distributor of high-quality food products such as coconut cream and yeast. Coming soon, our food basket will expand to include cooking oil and rice. \n' +
            '\n' +
            'We work with the best manufacturers to supply the market with delicious and nutritious food products. You can find our products at your nearest shop or supermarket. ',
        products: [
            {
                name: 'GSM Coconut Cream',
                image: 'https://ipfs.io/ipfs/bafybeia3tphwa2yohm3zvihx72keuv6t2drj43efamz2wy6hgk6inofzmy/GSM%20Coconut.png'
            },
            {
                name: 'Anchor Yeast',
                image: 'https://ipfs.io/ipfs/bafybeibtnu47clx3r4hrpnoh5lbdv2k22jaryk5itv2dnmoayu7xojtay4/Anchor-Yeast__600-X-460.png'
            },
            {
                name: 'Royale Tomato Paste',
                image: 'https://ipfs.io/ipfs/bafybeidg4ak5gr6dlvnuw3n336gr74bqthbye4aaohxmq3oz2ogheguv3e/GSM-TANZANIA-1-01-2048x1381.png'
            },
            {
                name: 'Tiger Head ',
                image: 'https://ipfs.io/ipfs/bafybeig4osv5csvfuvnjhn6gsoweizaoysluzy2zflia3mi56achc4qezu/trading101-01%20(1).png'
            },
        ]
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeihvkr5ubnxyhv5cxw5tpi3r46firv36mvr3ddttnn5ymry3wsyesy/Haier%20Prodcuct%20Group.png',
        detail: 'Haie brand offers the best electronics home appliances that are both economical and affordable. Their appliances use little electricity, making them energy-efficient and environmentally friendly. With the incorporation of great technology, Haier appliances provide superior performance compared to other appliances in the market. Don\'t break the bank when shopping for home appliances - choose Haier brand and enjoy high-quality products without compromising on your budget. Haier products are available in Tanzania market and exclusively in our Salamander Tower Posta and Uhuru Street Kariakoo shops.',
        catalog_link: 'https://ipfs.io/ipfs/bafybeidkdatotfl2urm3x4rnlawiil45zh3myakk7zfeuuo3m56fsamxfm/HAIER_CATALOGUE.pdf',
        products: [
            {
                name: 'Televisions',
                image: 'https://ipfs.io/ipfs/bafybeifo6ctzewwzjfhb7oe56gdxybtw63dco7ghln5cppahpsjw3xpv5q/20221106-220125%20copy.png'
            },
            {
                name: 'Ovens',
                image: 'https://ipfs.io/ipfs/bafybeidyxq4jc55ryhkrqf5josz2ol5kvdqayfpkiwlnnfhxg2rq4csclu/ECR1031EESB-1-2048x2048.png'
            },
            {
                name: 'Refrigerator',
                image: 'https://ipfs.io/ipfs/bafybeicorodny77nfyk4yig7gzghgdvcq5ol27fkxxjeg53q7kx7w24l7e/HTF-456DM6-7.png'
            },
            {
                name: 'AC',
                image: 'https://ipfs.io/ipfs/bafybeiftchhozznggeda6wfzqsnp27wdhlquqnxxpqzoc5tyykk7p3m724/TK%E5%B7%A6%E4%BE%A7%E9%9D%A2%E5%BC%80%20copy.png'
            },
            {
                name: 'Chest Freezer',
                image: 'https://ipfs.io/ipfs/bafybeiehwlwih44n6c2cc6pjnxopvrr67niooa424fycq2o3eb3wdfnjru/Image%2013.png'
            },
            {
                name: 'Washing Machine',
                image: 'https://ipfs.io/ipfs/bafybeie2zuqfiymy4lqh2m2q52tdxtxiqnbrmwxsmbch2wpl3pw7w255ou/Wash%20Machine.png'
            }
        ]
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeigoandzqs666ki2w6mcvdd3epdl4vegblchbckpnfx6kmognm6bky/GSM%20Spare%20Parts.png',
        detail: 'GSM Spares offers quality spare parts, tires, and lubricants. We extend technical support, and consultancy to our customers. We are also the exclusive importer for Tiger Head batteries from China. \n' +
            '\n' +
            'Our products and services are offered to local and external markets in Tanzania, Zambia, South Africa and the DRC. ',
        products: [
            {
                name: 'Spare Parts\n' +
                    '(FAW, HOWO, BEINBEN, SHACKMAN)',
                image: 'https://ipfs.io/ipfs/bafybeiamjhkfzayb7hfawhps6w3rwbmdwpb25flboupqdbo4aryqfs4hlm/Spares-Parts-FAW-HowoBenBen-ShackMan600x460.png'
            },
            {
                name: 'Trailer Parts',
                image: 'https://ipfs.io/ipfs/bafybeid4dyx46kk2i2d5ri2756lkr4zyadpz36oz4rmgmnpeljmnupsogm/Trailer_SPARE-PART-600X460.png'
            },
            {
                name: 'Lubricants',
                image: 'https://ipfs.io/ipfs/bafybeidfgzw4bck42ccu3whhkwk2o6mszfgkioikma5oprkayeojfllf6u/Lubricant-600x460-1.png'
            },
            {
                name: 'Tyres',
                image: 'https://ipfs.io/ipfs/bafybeifnohidhk6ukgelh7qn37ew6cp5kw2h4hqccvu54khb7ggsa62wya/DoubleStar_0002_Vector-Smart-Object.png'
            },
            {
                name: 'Everstart Battery',
                image: 'https://ipfs.io/ipfs/bafybeihp5rn4x7t2o5mcfk4wyevf7rm5cm3ojmsbvgkyg466owxnxubr2e/Everstart_600-X-460-copy.png'
            },
            {
                name: 'Marco MF Battery',
                image: 'https://ipfs.io/ipfs/bafybeiadjrkt5bwvdi2axth62crjfhgdhm6stpv7ybsdfx5bvc3cv6atqu/Marco-Bettry_0005_Vector-Smart-Object.png'
            }
        ]
    }
];

export async function getGSMTrading() {
    const response = await httpGetRequest('/trade-companies?populate[logo][fields]=url&populate[catalog][fields]=url&populate[products][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}} = x ?? {};
        const {logo, detail, products} = attributes;
        return {
            logo: fileUrl(logo?.data?.attributes?.url),
            detail,
            products: itOrEmptyList(products).map(t => {
                return {
                    name: t?.name,
                    image: fileUrl(t?.image?.data?.attributes?.url)
                }
            })
        };
    });
}