import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import React from "react";
import Footer from "../../core/components/Footer";
import {CustomerService} from "../components/CustomerService";
import {Contact} from "../components/Contact";
import {Mail} from "../components/Mail";
import {ContactNewsAndUpdates} from "../components/NewsAndUpdates";
import {Location} from "../components/Location";
import {useScrollUp} from "../../core/hooks/scrollUp";


export function ContactUs() {
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <CustomerService/>
            <Contact/>
            <WhiteSpace height={100}/>
            <Mail/>
            <WhiteSpace height={100}/>
            <Location/>
            <WhiteSpace height={100}/>
            <ContactNewsAndUpdates/>
            <WhiteSpace height={100}/>
            <Footer/>
        </div>
    )
}