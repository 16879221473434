import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList, pathUrl} from "../../core/services/utils.mjs";

const defaultCompanies = [
    {
        cluster_name: 'Logistics',
        id: 'logistics',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeiasu4zw464scktutmhndvfazakxz3mm4awze5flbk66ltjnpfptty/galcon_icon.png',
        cluster_image: 'https://ipfs.io/ipfs/bafybeicok5lrmfsuhwaqtzeeyk33enubyxic2swfmafytkacoj4cjdqb2a/Rectangle%202678.png'
    },
    {
        cluster_name: 'Manufacturing',
        id: 'manufacturing',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeia7qo3a45hf7a4cvqkxj3su5bipyh4v43u2ocotzeagz6pcf3hva4/LOGO%20GSM%20TANZANIA-01.png',
        cluster_image: 'https://ipfs.io/ipfs/bafybeiajybeuugex4ikisr2jigjzm62h2jvvs6qnlmnoher5cvgyd7hory/shutterstock_1870491376.jpg'
    },
    {
        cluster_name: 'Trading',
        id: 'trading',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeia7qo3a45hf7a4cvqkxj3su5bipyh4v43u2ocotzeagz6pcf3hva4/LOGO%20GSM%20TANZANIA-01.png',
        cluster_image: 'https://ipfs.io/ipfs/bafybeihl5stlrawiqe5gw7se3arwe3grbyzmst7wz3qrp5h5jqbmbtygua/Trading.png'
    },
    {
        cluster_name: 'Retail',
        id: 'retail',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeifd6f5rzgzmnnp2bz4qpz4jo6bdtukiolpxjqp66gq35xeucsjazq/GSM%20Mall%20copy.png',
        cluster_image: 'https://ipfs.io/ipfs/bafybeifxz6f6hnmfj7y5tulvuumfs4tfwgy53rao37ngsfntt2clfr3o74/shutterstock_426744064%20(2).png'
    },
];

export async function getGSMCompanies() {
    const response = await httpGetRequest('/companies?populate[cluster_logo][fields]=url&populate[cluster_image][fields]=url&populate[href][fields]=path');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length===0?defaultCompanies:itOrEmptyList(data).map(x=>{
        const {attributes = {}} = x;
        const {cluster_name,href,cluster_logo,cluster_image} = attributes;
        return {
            cluster_name,
            id: pathUrl(href?.data?.attributes?.path),
            cluster_logo: fileUrl(cluster_logo?.data?.attributes?.url),
            cluster_image: fileUrl(cluster_image?.data?.attributes?.url)
        }
    });
}