import Container from "../../core/components/Container";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Paragraph from "../../core/components/Paragraph";
import {useEffect, useState} from "react";
import {getAboutUsDetails} from "../services/about.mjs";
import {GSMVideoPlayer} from "../../core/components/VideoPlayer";
import {gsmRedColor} from "../../core/services/colors.mjs";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";

export function HomeAboutUs() {
    const [about, setAbout] = useState({
        mission: 'GSM Group is an innovation-driven conglomerate that offers a wide range of services\n' +
            '                        across the\n' +
            '                        African continent.',
        vision: 'We are committed to providing excellent services and quality products while bringing together\n' +
            '                        companies, talented individuals, and first-class resources.'
    });

    useEffect(() => {
        getAboutUsDetails().then(setAbout).catch(r => {
            console.log(r);
        });
    }, []);
    const isSmallScren = getScreenType()===SCREEN_WIDTH.COMPACT;
    return (
        <div>
            <Container>
                <div style={{
                    display: isSmallScren?'block':'flex',
                    flexDirection: 'row',
                    alignItems: "center"
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: isSmallScren?'flex-start':"flex-end",
                        marginRight: 16,
                        width: '100%'
                    }}>
                        <HeadlineSmall fontWeight={500}>
                            ABOUT US
                        </HeadlineSmall>
                        <HeadlineLarge fontWeight={900} color={gsmRedColor}>
                            GSM GROUP
                        </HeadlineLarge>
                        <HeadlineLarge fontWeight={900} color={gsmRedColor}>
                            OF COMPANIES
                        </HeadlineLarge>
                    </div>
                    <div style={{
                        width: 75,
                        display: isSmallScren?'none':"flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}>
                        <div style={{
                            position: 'relative',
                            height: '100%',
                            minHeight: 300,
                            width: 3,
                            borderRadius: 5,
                            backgroundColor: gsmRedColor
                        }}/>
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            backgroundColor: gsmRedColor,
                            position: 'absolute',
                            top: 0
                        }}/>
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            backgroundColor: gsmRedColor,
                            position: 'absolute',
                            bottom: 0
                        }}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: "flex-start",
                        width: '100%'
                    }}>
                        <WhiteSpace height={16}/>
                        <Paragraph>
                            {about?.short_description}
                        </Paragraph>

                        {/*<Paragraph>*/}
                        {/*    {about?.vision}*/}
                        {/*</Paragraph>*/}
                    </div>
                </div>
            </Container>
            <WhiteSpace height={24}/>
            {/*<GSMVideoPlayer src={about?.video}/>*/}
        </div>
    );
}