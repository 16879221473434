import {AppHeader} from "../../core/components/AppHeader";
import Footer from "../../core/components/Footer";
import NewsUpdates from "../../core/components/NewsUpdates";
import {AboutMenu} from "../components/AboutMenu";
import {HomeAboutUs} from "../../home/components/HomeAboutUs";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import HomeGroupClients from "../../home/components/HomeGroupClients";
import HomeTeam from "../../home/components/HomeTeam";
import HomeCoreValue from "../../home/components/HomeCoreValues";
import {HomeDepartments} from "../../home/components/HomeDepartments";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import {gsmRedColor} from "../../core/services/colors.mjs";
import {useScrollUp} from "../../core/hooks/scrollUp";

export function AboutUs() {
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <AboutMenu activeLink={'/about_us'}/>
            <WhiteSpace height={24}/>
            <HomeAboutUs/>
            <WhiteSpace height={24}/>
            <HomeGroupClients/>
            <WhiteSpace height={58}/>
            <HomeTeam/>
            <WhiteSpace height={58}/>
            <HomeCoreValue/>
            <WhiteSpace height={58}/>
            <NewsUpdates/>
            <div style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <HeadlineSmall>SPECIALIZATION</HeadlineSmall>
                <HeadlineLarge color={gsmRedColor} fontWeight={900}>GSM GROUP FOCUS</HeadlineLarge>
            </div>
            <HomeDepartments onlyTitle={true}/>
            <WhiteSpace height={58}/>
            <Footer/>
        </div>
    )
}
