import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList, pathUrl} from "../../core/services/utils.mjs";

const defaultTeams = [
    {
        name: 'FELIX M. MAAGI',
        image: 'https://ipfs.io/ipfs/bafybeieulb3pks76dkxubcwyfltzmx5lfozkooq2zgysra46wketydy4hi/DSC_0884.png',
        position: 'CHIEF FINANCE OFFICER',
        office: 'GSM GROUP',
        linked_in: 'https://www.linkedin.com/in/felix-maagi-35161639/'
    },
    {
        name: 'CHENEDZO MUPUKUTA',
        image: 'https://ipfs.io/ipfs/bafybeiei2hnry3vfeeijucxwtiwrfy3pyhndh3xfzzibf6x4a77gbcwoae/DSC_0970.png',
        position: 'GROUP EXECUTIVE SECRETARY',
        office: 'PRESIDENT’S OFFICE',
        linked_in: 'https://www.linkedin.com'
    },
    {
        name: 'MOHAMMED SALEH',
        image: 'https://ipfs.io/ipfs/bafybeihjybmgfxk6lyiz73sd2zbiyoio3rjfrvyuzb36yv6z7xdceq7nim/DSC_0796.png',
        position: 'CHIEF INFORMATION OFFICER',
        office: 'GSM GROUP',
        linked_in: 'https://www.linkedin.com/in/mohammed-saleh-b20b1118/'
    },
    {
        name: 'DEODATUS MKUMBE',
        image: 'https://ipfs.io/ipfs/bafybeib3kieezcjsva7wl5cbljqrw3uqak6r2yhi4hb4ocg6h4cki5t6ta/DSC_0751.png',
        position: 'CHIEF HUMAN RESOURCE',
        office: 'MANAGER - GSM GROUP',
        linked_in: 'https://www.linkedin.com/in/deodatus-mkumbe-26904816b/'
    },
    {
        name: 'JOHN MUYEGETA',
        image: 'https://ipfs.io/ipfs/bafybeihpv6c2zhrc7dh6jdemqjzw7swjjitfabp64oimtnmi2r25j4wi7i/DSC_0878.png',
        position: 'CHIEF INTERNAL AUDITOR',
        office: 'GSM GROUP',
        linked_in: 'https://www.linkedin.com'
    },
    {
        name: 'FAHAD MOHAMED',
        image: 'https://ipfs.io/ipfs/bafybeifgvdcp36nbkw2vfuiyunvcknuyfhwkm7ftm7225jyxrwxjohtpdu/DSC_0847.png',
        position: 'HEAD OF TREASURY',
        office: 'GSM GROUP',
        linked_in: 'https://www.linkedin.com'
    }
];

const defaultHeads = [
    {
        name: 'AISHA MOHAMED',
        image: 'https://ipfs.io/ipfs/bafybeicam6bs7wkc6kekr5xj4plei4cdwlynly5rtybcxqwdlrbpn6jgvm/DSC_0790.png',
        position: 'CHIEF EXECUTIVE OFFICER',
        cluster_name: 'Logistics',
        cluster_id: 'logistics',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeiasu4zw464scktutmhndvfazakxz3mm4awze5flbk66ltjnpfptty/galcon_icon.png',
        cluster_details: 'GALCO Ltd is our integrated third-party logistics (3PL) provider that is instrumental in the distribution of goods across Eastern, Southern and Central Africa. We currently service Tanzania, Zambia, Mozambique, Malwai, DRC, Rwanda, Burundi, South Sudan and South Africa by offering end to end solutions such as inland container depot, container freight stations, clearing and forwarding services as well as insurance and transportation within and outside of Tanzania.   ',
        cluster_image: 'https://ipfs.io/ipfs/bafybeicok5lrmfsuhwaqtzeeyk33enubyxic2swfmafytkacoj4cjdqb2a/Rectangle%202678.png'
    },
    {
        name: 'CPA BENSON MAHENYA',
        image: 'https://ipfs.io/ipfs/bafybeihk6fareiqik3qpknxt466eptejnfqtvvr4b5jz4vvv7tcspqmxyy/DSC_0727.png',
        position: 'CHIEF EXECUTIVE OFFICER',
        cluster_name: 'Manufacturing',
        cluster_id: 'manufacturing',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeia7qo3a45hf7a4cvqkxj3su5bipyh4v43u2ocotzeagz6pcf3hva4/LOGO%20GSM%20TANZANIA-01.png',
        cluster_details: 'GSM Tanzania is the exclusive importer of Tiger Head batteries from China, and a distributor of high-quality food products such as coconut cream and yeast. Coming soon, our food basket will expand to include cooking oil and rice. \n' +
            '\n' +
            'We work with the best manufacturers to supply the market with delicious and nutritious food products. You can find our products at your nearest shop or supermarket. ',
        cluster_image: 'https://ipfs.io/ipfs/bafybeiajybeuugex4ikisr2jigjzm62h2jvvs6qnlmnoher5cvgyd7hory/shutterstock_1870491376.jpg'
    },
    {
        name: 'ZAINAB ANAM',
        image: 'https://ipfs.io/ipfs/bafybeicligchibqup6ndgmu2jbbf436oh4dq2p5ffhpcq2tf3cgkl6pt2m/DSC_0739.png',
        position: 'CHIEF EXECUTIVE OFFICER',
        cluster_name: 'Trading',
        cluster_id: 'trading',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeia7qo3a45hf7a4cvqkxj3su5bipyh4v43u2ocotzeagz6pcf3hva4/LOGO%20GSM%20TANZANIA-01.png',
        cluster_details: 'GSM Tanzania is the exclusive importer of Tiger Head batteries from China, and a distributor of high-quality food products such as coconut cream and yeast. Coming soon, our food basket will expand to include cooking oil and rice. \n' +
            '\n' +
            'We work with the best manufacturers to supply the market with delicious and nutritious food products. You can find our products at your nearest shop or supermarket.',
        cluster_image: 'https://ipfs.io/ipfs/bafybeihl5stlrawiqe5gw7se3arwe3grbyzmst7wz3qrp5h5jqbmbtygua/Trading.png'
    },
    {
        name: 'Mr. YUSUPH ABDI',
        image: 'https://ipfs.io/ipfs/bafybeifd6f5rzgzmnnp2bz4qpz4jo6bdtukiolpxjqp66gq35xeucsjazq/GSM%20Mall%20copy.png',
        position: 'CHIEF EXECUTIVE OFFICER',
        cluster_name: 'Retail',
        cluster_id: 'retail',
        cluster_logo: 'https://ipfs.io/ipfs/bafybeifd6f5rzgzmnnp2bz4qpz4jo6bdtukiolpxjqp66gq35xeucsjazq/GSM%20Mall%20copy.png',
        cluster_details: 'We are the exclusive franchise retailer of Splash, Babyshop, Max, Shoexpress, HomeBox, Haier Electronics, GSM Sports (which includes Puma, Nike, Anta and adidas). We are dedicated to delivering exceptional retail services with on trend and high-quality products for all your shopping needs.\n' +
            '\n' +
            'We operate 45 apparel and home stores across Tanzania and Kenya. ',
        cluster_image: 'https://ipfs.io/ipfs/bafybeifxz6f6hnmfj7y5tulvuumfs4tfwgy53rao37ngsfntt2clfr3o74/shutterstock_426744064%20(2).png'
    },
];

export async function getGSMAboutGovernance() {
    const response = await httpGetRequest('/about-governance?populate[heads][populate][image][fields]=url&populate[heads][populate][cluster_logo][fields]=url&populate[teams][populate][image][fields]=url&populate[president][populate][image][fields]=url&populate[heads][populate][cluster_image][fields]=url&populate[heads][populate][href][fields]=path&populate[group_ceo][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    const {attributes = {}} = data;
    const {president, teams, heads,group_ceo} = attributes;
    return {
        president: {
            name: president?.name ?? 'GHALIB SAID MOHAMMED',
            detail: president?.detail ?? 'As the President of our innovation-driven conglomerate, it gives me great pleasure to introduce you to the exceptional individuals who form the backbone of our organization. At our company, we believe in the power of collaboration, the strength of diversity, and the pursuit of excellence.\n' +
                '\n' +
                'Our team is composed of dedicated professionals who are passionate about their work and strive to exceed expectations. Together, we aim to create synergies, leverage our collective expertise, and build strong partnerships that drive innovation and contribute to the progress of the communities we serve.',
            image: fileUrl(president?.image?.data?.attributes?.url) ?? 'https://ipfs.io/ipfs/bafybeigtpumz6ayn4eddetc4zzsg4gu6zguzwjxbdnzlugyjawwghpfxmq/Ghalib%20Picture.png'
        },
        group_ceo: {
            name: group_ceo?.name ?? 'Group CEO name',
            detail: group_ceo?.detail ?? 'Group CEO details',
            image: fileUrl(group_ceo?.image?.data?.attributes?.url) ?? 'https://ipfs.io/ipfs/bafybeigtpumz6ayn4eddetc4zzsg4gu6zguzwjxbdnzlugyjawwghpfxmq/Ghalib%20Picture.png'
        },
        teams: itOrEmptyList(teams?.data).length === 0 ? defaultTeams : itOrEmptyList(teams?.data).map(x => {
            const {attributes = {}} = x ?? {};
            const {name, image, position, office, linked_in} = attributes;
            return {
                name,
                image: fileUrl(image?.data?.attributes?.url),
                position,
                office,
                linked_in
            };
        }),
        heads: itOrEmptyList(heads?.data).length === 0 ? defaultHeads : itOrEmptyList(heads?.data).map(x => {
            const {attributes = {}} = x ?? {};
            const {
                name,
                image,
                position,
                cluster_image,
                href,
                cluster_name,
                cluster_details,
                cluster_logo
            } = attributes;
            return {
                name,
                image: fileUrl(image?.data?.attributes?.url),
                position,
                cluster_name,
                cluster_id: pathUrl(href?.data?.attributes?.path),
                cluster_logo: fileUrl(cluster_logo?.data?.attributes?.url),
                cluster_details,
                cluster_image: fileUrl(cluster_image?.data?.attributes?.url)
            };
        })
    }
}