import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl} from "../../core/services/utils.mjs";

export async function getGSMAboutCareer(){
    const response = await httpGetRequest('/about-career?populate[header][fields]=url&populate[middle][fields]=url&populate[footer][fields]=url&populate[faqs]=*&populate[positions]=*');
    const {data = {}} = response ?? {};
    const {attributes = {}} = data;
    const {header,middle,footer,positions,faqs,working_at_gsm,about} = attributes;
    return {
        about,
        working_at_gsm,
        header: fileUrl(header?.data?.attributes?.url),
        middle: fileUrl(middle?.data?.attributes?.url),
        footer: fileUrl(footer?.data?.attributes?.url),
        faqs,
        positions,
    }
}