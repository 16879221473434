import {gsmWhiteColor} from "../services/colors.mjs";
import {HeadlineSmall} from "./HeadlineSmall";

/**
 *
 * @param source
 * @param style
 * @param title
 * @param children {JSX.Element}
 * @param radius{number}
 * @param type{'cover' | 'contain' | 'auto'}
 * @returns {JSX.Element}
 * @constructor
 */
export function BackgroundImage(
    {
        source,
        style = {},
        title,
        children,
        radius = 8,
        type = 'cover'
    }
) {
    return (
        <div style={{...style ?? {}, ...{width: '100%', position: 'relative', height: '100%'}}}>
            <div
                style={{
                    backgroundImage: `url('${source}')`,
                    backgroundPosition: 'center',
                    backgroundSize: type ?? 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                    width: '100%',
                    borderRadius: radius
                }}
            />
            {(title || children) && <div style={{
                background: 'linear-gradient(0deg, rgb(0 0 0 / 55%) -5.65%, rgba(0, 0, 0, 0) 52.39%)',
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 8
            }}>
                <div style={{position: "absolute", bottom: 16, left: 16, width: '100%'}}>
                    {children ?? <HeadlineSmall color={gsmWhiteColor}>{title}</HeadlineSmall>}
                </div>
            </div>}
        </div>
    );
}
