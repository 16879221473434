import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultData = [
    {
        name: 'Max Stores',
        detail: 'Max offers excellent value in everyday essentials and is well known for its range of quality and well-priced fashion for the whole family. With a friendly shopping experience, Max delivers contemporary fashion, smart wear, activewear, accessories and footwear.',
        logo: 'https://ipfs.io/ipfs/bafybeifeu5pdqa5s4ktt4gff2xwna5ll7zfmivn3ioj3qgng6l2f3kiiua/Group%202057.svg',
        image: 'https://ipfs.io/ipfs/bafybeicctgsn24bpw7jp2hs34qwgcmxjwxfufdhw2gbbfsgkolk5p3b3bi/Max%20Model.png',
        locations: [
            {
                name: 'All',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Arusha TFA Plaza,' +
                    'Dodoma Shoppers Plaza,' +
                    'Zanzibar Michenzani Mall,' +
                    'Village Market,' +
                    'Village Market Sarit Center Center',
                image: 'https://ipfs.io/ipfs/bafybeibhgvssoepnnr6v7rki7famfwzho6zavn7bogdbx5cqytcuegsrhy/DSC_5044-scaled.png'
            },
            {
                name: 'Tanzania',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Arusha TFA Plaza,' +
                    'Dodoma Shoppers Plaza,' +
                    'Zanzibar Michenzani Mall',
                image: 'https://ipfs.io/ipfs/bafybeibhgvssoepnnr6v7rki7famfwzho6zavn7bogdbx5cqytcuegsrhy/DSC_5044-scaled.png'
            },
            {
                name: 'Kenya',
                detail:
                    'Village Market Sarit Center Center',
                image: 'https://ipfs.io/ipfs/bafybeibhgvssoepnnr6v7rki7famfwzho6zavn7bogdbx5cqytcuegsrhy/DSC_5044-scaled.png'
            }
        ],
    },
    {
        name: 'BABYSHOP Stores',
        detail: 'Babyshop has grown into a trusted destination for children’s fashion, nursery products, feeding essentials, travel systems and toys. We stay true in putting the customer first, staying young in our minds and ensuring that we offer quality, choice, and safety.',
        logo: 'https://ipfs.io/ipfs/bafybeibl57r4kft7evd2eggrw62nd4ppxkk27iahdi5zqa6jhteeolgfom/Group%202058.svg',
        image: 'https://ipfs.io/ipfs/bafybeibse7iq4hambcq37snfzssrulxjwjgei4p5jv4tn7jbtsy4k3unei/Group%202059.png',
        locations: [
            {
                name: 'All',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Dodoma Shoppers Plaza,' +
                    'Village Market,' +
                    'Village Market Sarit Center Center,' +
                    'The Hub',
                image: 'https://ipfs.io/ipfs/bafybeifomulktagvus3btfeebtpbfg6ybcdjg4ejkhok43wnzwqzehdfji/mlimani-city-03.png'
            },
            {
                name: 'Tanzania',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Dodoma Shoppers Plaza',
                image: 'https://ipfs.io/ipfs/bafybeifomulktagvus3btfeebtpbfg6ybcdjg4ejkhok43wnzwqzehdfji/mlimani-city-03.png'
            },
            {
                name: 'Kenya',
                detail:
                    'Village Market Sarit Center Center,' +
                    'The Hub',
                image: 'https://ipfs.io/ipfs/bafybeifomulktagvus3btfeebtpbfg6ybcdjg4ejkhok43wnzwqzehdfji/mlimani-city-03.png'
            }
        ],
    },
    {
        name: 'Splash Stores',
        detail: 'Leading fashion brand in ladies and men’s clothing and accessories. Splash offers smart, casual, and occasional wear that captures the essence of fashion in everyday life and connects with its audiences through collection, emotion, and attitude.',
        logo: 'https://ipfs.io/ipfs/bafybeidrwxawkmje27e6dlwjgkjhnevclkzjerj6g4vw3ehlulddld25em/Group%202060.svg',
        image: 'https://ipfs.io/ipfs/bafybeigixg56hbqh6vxvjmxjrd2t4fqjmuirznuiufkjkvy36hotupzvqy/Splash%20Model.png',
        locations: [
            // {
            //     name: 'All',
            //     detail: 'GSM Mall Msasani,' +
            //         'Mlimani City Mall,' +
            //         'Salamander Tower,' +
            //         'GSM Mall Pugu,' +
            //         'Dodoma Shoppers Plaza',
            //ipfs.io/ipfs/bafybeiatcxcluqtdwrlis3swogoutrxirqt4r5ivwqztnnlhl5ee4vmdsq/mlimani-city-01.png'
            // },
            {
                name: 'Tanzania',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Dodoma Shoppers Plaza',
                image: 'https://ipfs.io/ipfs/bafybeiatcxcluqtdwrlis3swogoutrxirqt4r5ivwqztnnlhl5ee4vmdsq/mlimani-city-01.png'
            },
            // {
            //     name: 'Kenya',
            //     detail: 'GSM Mall Msasani,' +
            //         'Mlimani City Mall,' +
            //         'Salamander Tower,' +
            //         'GSM Mall Pugu,' +
            //         'Dodoma Shoppers Plaza',
            //ipfs.io/ipfs/bafybeiatcxcluqtdwrlis3swogoutrxirqt4r5ivwqztnnlhl5ee4vmdsq/mlimani-city-01.png'
            // }
        ],
    },
    {
        name: 'GSM Sports Stores',
        detail: '',
        logo: 'https://ipfs.io/ipfs/bafybeifc6mgr5vnlbp3jvsyjsbrf5chkxegkmp2ecafuhcxlb7zlsjhxam/GSM%20Sports.svg',
        image: 'https://ipfs.io/ipfs/bafybeifgwaehdvpsy2bx75ekfiy2kibd7hdziirnlwl4abbfkgzmwaiijq/Group%202061.png',
        locations: [
            {
                name: 'All',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Village Market,' +
                    'Village Market Sarit Center',
                image: 'https://ipfs.io/ipfs/bafybeigiv66xlo2fo4oahaspmzzhch4j67uwmhsf7mxqrtvkzfu4y2ct4e/mlimani-city-02.png'
            },
            {
                name: 'Tanzania',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,',
                image: 'https://ipfs.io/ipfs/bafybeigiv66xlo2fo4oahaspmzzhch4j67uwmhsf7mxqrtvkzfu4y2ct4e/mlimani-city-02.png'
            },
            {
                name: 'Kenya',
                detail:
                    'Village Market Sarit Center',
                image: 'https://ipfs.io/ipfs/bafybeigiv66xlo2fo4oahaspmzzhch4j67uwmhsf7mxqrtvkzfu4y2ct4e/mlimani-city-02.png'
            }
        ]
    },
    {
        name: 'ShoeExpress Stores',
        detail: 'Shoexpress aims to offer customers a real value experience, through an extensive assortment of fashionable footwear and accessories at best prices.',
        image: 'https://ipfs.io/ipfs/bafybeiasypawm6drmlzuge436fi57wpqrocnqbuf2h6n7d6lixbmtw2p5u/SE%20Model.png',
        logo: 'https://ipfs.io/ipfs/bafybeic7m4npihkpyiavz36xqyo6562jffhutdlfrik4zz3qbx7heuozoa/Group%202062.svg',
        locations: [
            // {
            //     name: 'All',
            //     detail: 'GSM Mall Msasani,' +
            //         'Mlimani City Mall,' +
            //         'Salamander Tower,' +
            //         'GSM Mall Pugu,' +
            //         'Dodoma Shoppers Plaza',
            //ipfs.io/ipfs/bafybeidpcdyrp3bhnbw6ze2oijjsdd5hhb6kflkx7tyewwll7jmpeocswi/SE-Zanzibar.png'
            // },
            {
                name: 'Tanzania',
                detail: 'GSM Mall Msasani,' +
                    'Mlimani City Mall,' +
                    'Salamander Tower,' +
                    'GSM Mall Pugu,' +
                    'Dodoma Shoppers Plaza',
                image: 'https://ipfs.io/ipfs/bafybeidpcdyrp3bhnbw6ze2oijjsdd5hhb6kflkx7tyewwll7jmpeocswi/SE-Zanzibar.png'
            },
            // {
            //     name: 'Kenya',
            //     detail: 'GSM Mall Msasani,' +
            //         'Mlimani City Mall,' +
            //         'Salamander Tower,' +
            //         'GSM Mall Pugu,' +
            //         'Dodoma Shoppers Plaza',
            //ipfs.io/ipfs/bafybeidpcdyrp3bhnbw6ze2oijjsdd5hhb6kflkx7tyewwll7jmpeocswi/SE-Zanzibar.png'
            // }
        ],
    },
    {
        name: 'Ipanema Stores',
        detail: 'Shoexpress aims to offer customers a real value experience, through an extensive assortment of fashionable footwear and accessories at best prices.',
        image: 'https://ipfs.io/ipfs/bafybeifkjyhnboj2dm5r5hkkym4vxjs6vt6b5rzscfdgnxlky2wciuxwwq/Ipanema%20Model.png',
        logo: 'https://ipfs.io/ipfs/bafybeiacpizbygba3v66bvq27wwak5ad2yq6mw6zcjoex447fyp452kaly/Group%202063.svg',
        locations: [
            // {
            //     name: 'All',
            //     detail: 'GSM Mall Msasani,' +
            //         'Mlimani City Mall,' +
            //         'Salamander Tower,' +
            //         'GSM Mall Pugu,' +
            //         'Village Market,' +
            //         'Village Market Sarit Center',
            //ipfs.io/ipfs/bafybeiegbyzyt4l3ptdt3zag5jg2zkpwoqgnoz3bzjqplp5lb6a5iy5u5m/Ipanema%20Store-%20SM%20Sta%20Rosa2.png'
            // },
            // {
            //     name: 'Tanzania',
            //     detail: 'GSM Mall Msasani,' +
            //         'Mlimani City Mall,' +
            //         'Salamander Tower,' +
            //         'GSM Mall Pugu,' +
            //         'Village Market,' +
            //         'Village Market Sarit Center',
            //ipfs.io/ipfs/bafybeiegbyzyt4l3ptdt3zag5jg2zkpwoqgnoz3bzjqplp5lb6a5iy5u5m/Ipanema%20Store-%20SM%20Sta%20Rosa2.png'
            // },
            {
                name: 'Kenya',
                detail: 'Village Market Sarit Center',
                image: 'https://ipfs.io/ipfs/bafybeiegbyzyt4l3ptdt3zag5jg2zkpwoqgnoz3bzjqplp5lb6a5iy5u5m/Ipanema%20Store-%20SM%20Sta%20Rosa2.png'
            }
        ],
    }
];

export async function getGSMRetailFashionContents() {
    const response = await httpGetRequest('/retail-fashions?populate[logo][fields]=url&populate[image][fields]=url&populate[locations][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}} = x ?? {};
        const {logo, image, name, detail, locations} = attributes;
        return {
            name,
            detail,
            logo: fileUrl(logo?.data?.attributes?.url),
            image: fileUrl(image?.data?.attributes?.url),
            locations: itOrEmptyList(locations).map(y => {
                return {
                    name: y?.name,
                    detail: `${y?.detail}`.split(','),
                    image: fileUrl(y?.image?.data?.attributes?.url)
                }
            })
        };
    });
}