export default function Paragraph({children, color = '#002020', textAlign='justify'}) {
    return (
        // <BodyLarge color={color} textAlign={textAlign}>{children}</BodyLarge>
        <span style={{
            fontSize: '1.275em',
            color: color,
            fontWeight: 300,
            fontFamily: 'Open Sans',
            textAlign: textAlign
        }}>{children}</span>
    )
}