import {WhiteSpace} from "../../core/components/WhiteSpace";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import React from "react";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";

export function BodyItem({image, name, cover = true}) {
    const isSmallScreen = getScreenType()===SCREEN_WIDTH.COMPACT
    return (
        <>
            <WhiteSpace height={24}/>
            <div style={{width: '100%', height: isSmallScreen?200:400}}>
                <BackgroundImage
                    type={cover === true ? 'cover' : 'auto'}
                    title={name} source={image}
                    style={{width: '100%', height: isSmallScreen?150:350}}/>
                {/*<img alt={name} style={{width: '100%', borderRadius: 10}} src={image}/>*/}
            </div>
        </>
    )
}
