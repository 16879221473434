import {WhiteSpace} from "../../core/components/WhiteSpace";
import {BodyLarge} from "../../core/components/BodyLarge";
import React from "react";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {Link} from "react-router-dom";
import {gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {BodyMedium} from "../../core/components/BodyMedium";

export function HeadItem({logo, detail, catalog_link}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <>
            <div style={{display: "flex", flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center'}}>
                <div style={{padding: 16, flexGrow: 0}}>
                    <img alt={'logo'} style={{width: 100, height: 'auto'}} src={logo}/>
                </div>
                <div style={{flexGrow: 1, padding: 16}}>
                    <WhiteSpace width={16}/>
                    <BodyLarge>{detail}</BodyLarge>
                    {
                        catalog_link && <>
                            <WhiteSpace height={16}/>
                            <Link to={catalog_link} target={'_blank'}>
                                <div style={{
                                    width: '100%',
                                    maxWidth: 200,
                                    backgroundColor: gsmRedColor,
                                    borderRadius: 4,
                                    // padding: 8,
                                    height: 48,
                                    cursor: 'pointer',
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <BodyMedium fontWeight={600} color={gsmWhiteColor}>GET CATALOG</BodyMedium>
                                </div>
                            </Link>
                        </>
                    }
                </div>
            </div>
            <WhiteSpace height={16}/>
        </>
    )
}