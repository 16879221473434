import {DEPARTMENT_SLAGS} from "../models/departments.mjs";
import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList, pathUrl} from "../../core/services/utils.mjs";

const defaultData = [
    {
        name: 'Transport & Logistic',
        title: 'Transport & Logistic',
        slag: DEPARTMENT_SLAGS.transport,
        id: 'logistics',
        links: [
            {
                name: 'All GALCO Services',
                href: '#'
            }
        ],
        features: [
            {
                name: 'Internal Container Depo Services',
                image: 'https://ipfs.io/ipfs/bafybeibputnwuyusamiy5x33fqbun3ctkzdvd322y23innzbmkb5tma2eq/shutterstock_552634261.png'
            },
            {
                name: 'Container Freight Station ',
                image: 'https://ipfs.io/ipfs/bafybeieqvnhitxuq5ia3tz46nz3j4jgqxt7mkvqft3mkzrftmnpqcb2ewm/shutterstock_426744064.png'
            },
            {
                name: 'Road Transport',
                image: 'https://ipfs.io/ipfs/bafybeialcecnerhtpvip2trzv736fbcaenqnsiu5gu6vemgfvkrar62cbm/Truck%20Galco.png'
            },
            {
                name: 'Port & Border Clearance Service',
                image: 'https://ipfs.io/ipfs/bafybeifavspehhubgnitsrobf2aaahgh37u4bk3q7nx4q7iarp2tgacht4/shutterstock_2123635136.png'
            },
            {
                name: 'Insurance Broker Services',
                image: 'https://ipfs.io/ipfs/bafybeicmdnbdv7haxyu5uavltdez7ar3ginixqvwmblhgytimdhunjh52m/shutterstock_670636564.png'
            },
        ]
    },
    {
        name: 'Fashion & Sports',
        title: 'Fashion & Sports',
        slag: DEPARTMENT_SLAGS.fashion,
        id: 'retail',
        links: [
            {
                name: 'Fashion For Family',
                href: '#'
            },
            {
                name: 'Sports Wear & Essentials',
                href: '#'
            },
            {
                name: 'Baby Products',
                href: '#'
            }
        ],
        features: [
            {
                name: 'Sports Wear & Essentials',
                image: 'https://ipfs.io/ipfs/bafybeidus2mfoivg3emosszzfoiyxzhypfby4et7fapvv5lp4ij7x2i7ua/SE-Zanzibar.jpg'
            }
        ]
    },
    {
        name: 'Foods & Beverage',
        title: 'Foods & Beverage',
        slag: DEPARTMENT_SLAGS.food,
        id: 'trading',
        links: [
            // {
            //     name: 'All GALCO Services',
            //     href: '#'
            // }
        ],
        features: [
            {
                name: 'GSM Coconut Cream',
                image: 'https://ipfs.io/ipfs/bafybeia3tphwa2yohm3zvihx72keuv6t2drj43efamz2wy6hgk6inofzmy/GSM%20Coconut.png'
            },
            {
                name: 'Anchor Yeast',
                image: 'https://ipfs.io/ipfs/bafybeibtnu47clx3r4hrpnoh5lbdv2k22jaryk5itv2dnmoayu7xojtay4/Anchor-Yeast__600-X-460.png'
            },
            {
                name: 'Royale Tomato Paste',
                image: 'https://ipfs.io/ipfs/bafybeidg4ak5gr6dlvnuw3n336gr74bqthbye4aaohxmq3oz2ogheguv3e/GSM-TANZANIA-1-01-2048x1381.png'
            }
        ]
    },
    {
        name: 'Home & Office Furniture',
        title: 'Home & Office furniture',
        slag: DEPARTMENT_SLAGS.furniture,
        id: 'retail',
        links: [
            {
                name: 'Home Furniture',
                href: '#'
            },
            {
                name: 'Office Furniture',
                href: '#'
            },
            {
                name: 'Garden Furniture',
                href: '#'
            },
            {
                name: 'Decor & Essential',
                href: '#'
            }
        ],
        features: [
            {
                name: 'Home Furniture',
                image: 'https://ipfs.io/ipfs/bafybeid57k3dovogzw7f6tmla5jmx5zky53xeonwnwnu5gi4ym7nvhfslq/Rectangle%202736.png'
            },
            {
                name: 'Office Furniture',
                image: 'https://ipfs.io/ipfs/bafybeihbpuftf4eattjadid7bjctpmrvdtgay4e7dbgmpcuao6n3pq54xy/Rectangle%202797.png'
            },
            {
                name: 'Garden Furniture',
                image: 'https://ipfs.io/ipfs/bafybeibie5zin7lo33ugfeoiriqjbfy7zynovlv6wggf2akmajgyczbxru/Rectangle%202815.png'
            }
        ]
    },
    {
        name: 'Trucks Spare Parts',
        title: 'Trucks Spare Parts',
        slag: DEPARTMENT_SLAGS.trucksSpare,
        id: 'trading',
        links: [
            {
                name: 'Spare Parts',
                href: '#'
            },
            {
                name: 'Trailer Parts',
                href: '#'
            },
            {
                name: 'Lubricants',
                href: '#'
            },
            {
                name: 'Tyres & Batteries',
                href: '#'
            }
        ],
        features: [
            {
                name: 'Spare Parts\n' +
                    '(FAW, HOWO, BEINBEN, SHACKMAN)',
                image: 'https://ipfs.io/ipfs/bafybeiamjhkfzayb7hfawhps6w3rwbmdwpb25flboupqdbo4aryqfs4hlm/Spares-Parts-FAW-HowoBenBen-ShackMan600x460.png'
            },
            {
                name: 'Trailer Parts',
                image: 'https://ipfs.io/ipfs/bafybeid4dyx46kk2i2d5ri2756lkr4zyadpz36oz4rmgmnpeljmnupsogm/Trailer_SPARE-PART-600X460.png'
            },
            {
                name: 'Lubricants',
                image: 'https://ipfs.io/ipfs/bafybeidfgzw4bck42ccu3whhkwk2o6mszfgkioikma5oprkayeojfllf6u/Lubricant-600x460-1.png'
            },
            {
                name: 'Tyres',
                image: 'https://ipfs.io/ipfs/bafybeifnohidhk6ukgelh7qn37ew6cp5kw2h4hqccvu54khb7ggsa62wya/DoubleStar_0002_Vector-Smart-Object.png'
            },
            {
                name: 'Everstart Battery',
                image: 'https://ipfs.io/ipfs/bafybeihp5rn4x7t2o5mcfk4wyevf7rm5cm3ojmsbvgkyg466owxnxubr2e/Everstart_600-X-460-copy.png'
            },
            {
                name: 'Marco MF Battery',
                image: 'https://ipfs.io/ipfs/bafybeiadjrkt5bwvdi2axth62crjfhgdhm6stpv7ybsdfx5bvc3cv6atqu/Marco-Bettry_0005_Vector-Smart-Object.png'
            }
        ]
    },
    {
        name: 'CSR & Sponsorship',
        title: 'CSR & Sponsorship',
        slag: DEPARTMENT_SLAGS.crs,
        id: '/about_sponsorship',
        // links: [
        //     {
        //         name: 'All GALCO Services',
        //         href: '#'
        //     }
        // ],
        features: [
            {
                name: 'GSM Foundation',
                image: 'https://ipfs.io/ipfs/bafybeih4ckqeaxiy57n7qcd3afyayzxyu374cyjpqxxgvyfux7sr6wn77m/WhatsApp%20Image%202023-07-31%20at%2009.04.16.jpeg'
            }
        ]
    },
    {
        name: 'Building Materials',
        title: 'Building Materials',
        slag: DEPARTMENT_SLAGS.building,
        id: 'manufacturing',
        links: [
            {
                name: 'Roofing Sheets',
                href: '#'
            },
            {
                name: 'Galvanised Wire',
                href: '#'
            },
            {
                name: 'Steel Nails',
                href: '#'
            },
            {
                name: 'Wire Mesh',
                href: '#'
            },
            {
                name: 'Wire',
                href: '#'
            },
            {
                name: 'Zinc Roof',
                href: '#'
            },
        ],
        features: [
            {
                name: 'Building Materials',
                image: 'https://ipfs.io/ipfs/bafybeibteabgokbcgbrjfhlewtjjyvbyihmtnahdkzyczsbccgdvgsmh74/shutterstock_1954895734.png',
            }
        ]
    },
    {
        name: 'Electronics Appliances',
        title: 'Electronics Appliances',
        slag: DEPARTMENT_SLAGS.electronics,
        id: 'trading',
        links: [
            {
                name: 'Television',
                href: '#'
            },
            {
                name: 'Oven',
                href: '#'
            },
            {
                name: 'Refrigerator',
                href: '#'
            },
            {
                name: 'AC & Chest Freezer',
                href: '#'
            },
            {
                name: 'Refrigerator',
                href: '#'
            },
        ],
        features: [
            {
                name: '',
                image: 'https://ipfs.io/ipfs/bafybeibehjywtfa5rr3y5lnauji35hcbnmvtlj7h3wsc55qjstyorl6vyi/Haier.png'
            },
        ]
    },
];

export async function getDepartments() {
    const response = await httpGetRequest('/home-departments?populate[href][fields]=path&populate[links][populate][href][fields]=path&populate[features][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}, id} = x;
        const {name, title, slag, href, links, features} = attributes;
        return {
            name: name,
            title: title,
            slag: slag,
            id: pathUrl(href?.data?.attributes?.path),
            links: itOrEmptyList(links).map(y => {
                return {
                    name: y?.name,
                    href: pathUrl(y?.href?.data?.attributes?.path)
                }
            }),
            features: itOrEmptyList(features).map(k => {
                return {
                    name: k?.name,
                    image: fileUrl(k?.image?.data?.attributes?.url)
                };
            })
        };
    })
}