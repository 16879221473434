import {useMemo} from "react";
import {getScreenType, SCREEN_WIDTH} from "../services/layout";

export default function Container({children, color}) {
    const getPad = useMemo(() => {
        return () => {
            const st = getScreenType();
            if (st === SCREEN_WIDTH.COMPACT) {
                return 16;
            } else if (st === SCREEN_WIDTH.MEDIUM) {
                return 24
            } else {
                return 24;
            }
        }
    }, [])
    return (
        <div style={{
            paddingLeft: getPad(),
            paddingRight: getPad(),
            backgroundColor: color,
            maxWidth: 1000,
            margin: "auto"
        }}>
            {children}
        </div>
    )
}