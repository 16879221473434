import React from "react";
import {WhiteSpace} from "./WhiteSpace";
import Paragraph from "./Paragraph";
import Map from "../../home/assets/pngs/map.png";
import GSMWhiteLogo from "../../home/assets/pngs/gsm-white-logo.png";
import {ReactComponent as BackToTopButton} from "../../home/assets/svgs/back-to-top-icon.svg";
import {ReactComponent as Mail} from "../../home/assets/svgs/mail-icon.svg";
import {ReactComponent as Phone} from "../../home/assets/svgs/phone-icon.svg";
import {ReactComponent as Whatsapp} from "../../home/assets/svgs/whatsapp-icon.svg";
import {gsmRedColor} from "../services/colors.mjs";
import {Link} from "react-router-dom";
import {getScreenType, SCREEN_WIDTH} from "../services/layout";
import {SocialIcons} from "./SocialIcons";

export default function Footer() {
    const contacts = [
        {name: 'TANZANIA', value: '+255745000000'},
        {name: 'ZAMBIA', value: '+260964002000'},
        {name: 'KENYA', value: '+254720522722'},
        {name: 'SOUTH AFRICA', value: '+27105959439'},
        {name: 'UNITED ARAB EMIRATES', value: '+97148806173'}
    ];

    const isLargeScreen = getScreenType() === SCREEN_WIDTH.EXPANDED
    return (
        <>
            <div style={{
                // width: '100%',
                padding: 24,
                flexDirection: isLargeScreen ? 'row' : 'column',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#303030'
            }}>
                <div style={{flex: 1}}>
                    <img src={GSMWhiteLogo} height={120} alt={'Logo'}/>
                    <WhiteSpace height={20}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Paragraph color={'white'}>Salamander Tower,</Paragraph>
                        <Paragraph color={'white'}>Samora Avenue</Paragraph>
                        <Paragraph color={'white'}>(Near Askari Monument)</Paragraph>
                        <Paragraph color={'white'}>PO Box 6244</Paragraph>
                        <Paragraph color={'white'}>Dar Es Salaam, Tanzania</Paragraph>
                    </div>
                    <WhiteSpace height={70}/>
                    <SocialIcons/>
                </div>
                <div style={{flex: 1}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {/*<HeadlineLarge color={'white'}>WE ARE CLOSE TO YOU.</HeadlineLarge>*/}
                        <WhiteSpace height={50}/>
                        <img src={Map} height={238} style={{maxWidth: 450}} width={'100%'} alt={'Map'}/>
                    </div>
                </div>
                <div style={{flex: 1, marginLeft: 8}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 16
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <Mail/>
                            <WhiteSpace width={20}/>
                            <Paragraph color={'white'}>info@gsmgroup.africa</Paragraph>
                        </div>
                        <WhiteSpace height={16}/>
                        {
                            contacts.map((element, index) => {
                                return (
                                    <div key={`${index}contacts`}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                            <Link target={'_blank'} to={`https://wa.me/${element?.value}`}>
                                                <Whatsapp/>
                                            </Link>
                                            <WhiteSpace width={16}/>
                                            <Link target={'_blank'} to={`tel:${element?.value}`}>
                                                <Phone/>
                                            </Link>
                                            <WhiteSpace width={16}/>
                                            <Paragraph color={'white'}>
                                                {element?.name}
                                            </Paragraph>
                                        </div>
                                        <WhiteSpace height={16}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div style={{
                flexDirection: 'row',
                background: gsmRedColor,
                justifyContent: 'space-between',
                padding: 50,
                display: 'flex',
            }}>
                <div>
                    <Paragraph color={'white'}>All right reserved</Paragraph>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <Paragraph color={'white'}>Back To Top</Paragraph>
                    <WhiteSpace width={10}/>
                    <BackToTopButton/>
                </div>
            </div>
        </>
    )
}