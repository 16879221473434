import React from "react";
import Container from "../../core/components/Container";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Paragraph from "../../core/components/Paragraph";
import {gsmWhiteColor} from "../../core/services/colors.mjs";
import {ReactComponent as CallIcon} from "../assets/svg/call-icon.svg";
import {ReactComponent as LocationIcon} from "../assets/svg/location-icon.svg";
import {ReactComponent as MailIcon} from "../assets/svg/mails-icon.svg";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";


export function Contact() {

    const address = [
        'Salamander Tower,',
        'Samora Avenue (Near Askari Monument)',
        'PO Box 6244',
        'Dar Es Salaam, Tanzania'
    ]
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <Container>
            <div style={{
                marginTop: -60,
                flexDirection: isSmallScreen ? 'column' : 'row',
                // justifyContent: 'space-between',
                display: 'flex',
            }}>
                <div style={{
                    background: '#EDEDED',
                    borderRadius: 10,
                    padding: 24,
                    maxWidth: 600,
                    marginBottom: isSmallScreen?16:0,
                    zIndex: 9999
                }}>
                    <CallIcon/>
                    <WhiteSpace height={20}/>
                    <Paragraph>+255 745 000 000</Paragraph>
                    <WhiteSpace height={30}/>
                    <MailIcon/>
                    <WhiteSpace height={20}/>
                    <Paragraph>info@gsmgroup.africa</Paragraph>
                </div>
                <WhiteSpace width={isSmallScreen ? 0 : 16}/>
                <div style={{
                    background: '#303030',
                    borderRadius: 10,
                    padding: 24,
                    maxWidth: 600,
                    zIndex: 9999
                }}>
                    <LocationIcon/>
                    <WhiteSpace height={20}/>
                    {
                        address.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Paragraph color={gsmWhiteColor}>{item}</Paragraph>
                                    <br/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Container>
    )
}