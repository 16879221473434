export function DisplayMedium({children, color, fontWeight = 700, textAlign='justify'}) {
    return (
        <span style={{
            textAlign,
            lineHeight: '1.05em',
            fontFamily: 'Open Sans',
            fontSize: '2.8125em',
            color,
            fontWeight,
        }}>{children}</span>
    );
}
