import {TitleMedium} from "./TitleMedium";
import {useState} from "react";
import {gsmRedColor} from "../services/colors.mjs";
import {Link} from "react-router-dom";

export function HeaderNavItem({name, onPress, color, link, active}) {
    const [focused, setFocused] = useState(false);
    return (
        <div
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
            onClick={onPress}
            style={{
                cursor: 'pointer',
                borderRadius: 8,
                background: active === true ? gsmRedColor : focused ? gsmRedColor : 'transparent'
            }}>
            <Link to={link}>
                <div style={{padding: '8px 16px 8px 16px',}}>
                    <TitleMedium fontWeight={600} color={focused ? '#fff' : color}>{name}</TitleMedium>
                </div>
            </Link>
        </div>
    );
}
