import {Center} from "../../core/components/Center";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import Paragraph from "../../core/components/Paragraph";
import {ReactComponent as IntegrityIcon} from '../assets/svgs/intergrity.svg';
import {ReactComponent as AccountabilityIcon} from '../assets/svgs/accountability.svg';
import {ReactComponent as EfficiencyIcon} from '../assets/svgs/efficiency.svg';
import {ReactComponent as TeamWorkIcon} from '../assets/svgs/teamwork.svg';
import {gsmRedColor} from "../../core/services/colors.mjs";

function CoreValue({title,description,icon,bgColor, textColor}) {
    return (
        <div style={{
            minHeight: '300px',
            maxWidth: '400px',
            borderRadius: '26px',
            backgroundColor: bgColor,
            padding: 24,
            margin: 10,
            flexDirection: 'column',
        }}>
            <div>
                {icon}
            </div>
            <WhiteSpace height={10}/>
            <HeadlineLarge fontWeight={900} color={gsmRedColor}>{title}</HeadlineLarge><br/>
            <WhiteSpace height={10}/>
            <Paragraph color={textColor}>{description}</Paragraph>
        </div>
    )
}


export default function HomeCoreValue(){
    return (
        <div>
            <Center>
                <HeadlineLarge fontWeight={900} color={gsmRedColor}>OUR CORE VALUES</HeadlineLarge>
            </Center>
            <WhiteSpace height={24}/>
            <div style={{
                flexDirection: 'column',
            }}>
                <Center>
                    <CoreValue
                        bgColor={'#F5F5F5'}
                        textColor={'#002020'}
                        icon={<IntegrityIcon />}
                        title={'Integrity'}
                        description={'We have the utmost care, respect, and compassion for our customers around the world. We always work for the benefit of the communities we serve.'} />
                    {/*<WhiteSpace width={24}/>*/}
                    <CoreValue
                        bgColor={'#303030'}
                        textColor={'#FFFFFF'}
                        icon={<AccountabilityIcon />}
                        title={'Accountability'}
                        description={'We conduct our business fairly, with honesty and transparency. Everything we do stands the test of public scrutiny.'}/>
                </Center>
                {/*<WhiteSpace height={24}/>*/}
                <Center>
                    <CoreValue
                        bgColor={'#303030'}
                        textColor={'#FFFFFF'}
                        icon={<EfficiencyIcon />}
                        title={'Efficiency'}
                        description={'We constantly strive to achieve the highest possible standard in our daily operations and in the quality of the goods and services we provide.'} />
                    {/*<WhiteSpace width={24}/>*/}
                    <CoreValue
                        bgColor={'#F5F5F5'}
                        textColor={'#002020'}
                        icon={<TeamWorkIcon />}
                        title={'Teamwork'}
                        description={'We work cohesively with our colleagues across divisions and with our customers and partners around the world, building strong relationships based on tolerance, understanding and cooperation.'}/>
                </Center>
            </div>
        </div>
    )
}