import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList, pathUrl} from "../../core/services/utils.mjs";

const defaultData = [
    {
        detail: 'We are the exclusive franchise retailer of Splash, Babyshop, Max, Shoexpress, HomeBox, GSM Sports (which includes Puma, Anta and Adidas) and our own private brand GSM Home. We are dedicated to delivering exceptional retail services with on trend and high-quality products for all your shopping needs.\n' +
            '\n' +
            'We operate 45 apparel and home stores across Tanzania and Kenya.',
        logo: 'https://ipfs.io/ipfs/bafybeibclhr7yn2gw732o4l7cf7ozynpioslctnhbev7ombsslxr54757q/GSM%20Mall%20copy%20(1).png',
        image: 'https://ipfs.io/ipfs/bafybeibd4wkux6pg2gjwykx4agyqg5g5dbpnjaraucefx5za6byusvuama/shutterstock_426744064%20(4).png',
        title: 'Fashion and Sports Apperals',
        id: 'fashion',
        brands: [
            {
                name: 'Max',
                bg: '#ffffff',
                image: 'https://ipfs.io/ipfs/bafybeiato5gxmlany4f24wnsfkkivqvt66vebuz4v65dyqfrdvnbm7xkeq/Group%201465.svg'
            },
            {
                name: 'Babyshop',

                bg: '#ffffff',
                image: 'https://ipfs.io/ipfs/bafybeiatm6dlob7mj7ilsfq37vz25k2qtayrklmlybmk2yhbmbl2p22n7i/Group%201467.svg'
            },
            {
                name: 'GSM Sports',
                bg: '#191818',
                image: 'https://ipfs.io/ipfs/bafybeid7f2toleifzifb6zrlnmwwtrxmpeoql2c3dca6hsb5y5fmf45zs4/Group%201486.svg'
            },
            {
                name: 'Ipamema',
                bg: '#C00550',
                image: 'https://ipfs.io/ipfs/bafybeigembsv76ytbmld65dhvue3yfgpxjr63z4jdvsdlfcsodtiw7csye/Group%201492.svg'
            },
            {
                name: 'Splash',
                bg: '#ffffff',
                image: 'https://ipfs.io/ipfs/bafybeierxgb4raxjw46at34cr6idmsl54swvyq4ue5b7i6eygqzx5va54a/Group%201482.svg'
            },
            {
                name: 'GSM Sports',
                image: 'https://ipfs.io/ipfs/bafybeiccdjnl26ixg3v7jzcibnrrue5nsap4w6hbdo4kimmll65pjvl7ve/Group%202055.svg',
                bg: '#ffffff'
            },
            {
                name: 'shoe express',
                bg: '#ffffff',
                image: 'https://ipfs.io/ipfs/bafybeifxv3vpleldgojanrmdbts5xjk42spqtiljgtgilla4ciysl6smly/Group%201483.svg'
            },
            {
                name: '',
                bg: 'transparent',
                height: 60,
                width: 300,
                image: 'hhttps://cms.gsmgroup.africa/uploads/explore_more_icon_8037d9ef1f.svg'
            }
        ]
    },
    {
        image: 'https://ipfs.io/ipfs/bafybeihkn5245vbwzdo6v26noaxupirtrnsxggkbelsjire46tmh5q5a4i/hisar%20koltuk%20tak%C4%B1m%C4%B1%202.png',
        title: 'Home and \n' +
            'Office Furniture',
        id: 'furniture',
        brands: [
            {
                name: 'GSM Home',
                bg: '#ffffff',
                image: 'https://ipfs.io/ipfs/bafybeihfaceewm3op7vcfeqhyzxco6gzeebv2mzed3h4vercympd4ry3ou/WhatsApp%20Image%202023-07-23%20at%2014.04.19.jpeg'
            },
            {
                name: 'Home Box',
                bg: '#ffffff',
                image: 'https://ipfs.io/ipfs/bafybeiffsqd7djkbxbez6aiqi3iuny4swyvw3emm6tv5sifkextj2e4t6a/Group%201501.svg'
            },
            // {
            //     name: 'Explore more',
            //     bg: 'transparent',
            //ipfs.io/ipfs/bafybeiexqvid2gzljfrf4d2ex57jy7oxa4elepyzoarvyirtdyp5tnmvw4/Group%202064.svg'
            // }
        ]
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeif2kcbscu2ptvfqpz3673fnkudgy3zsmiyil23civsfws6inijeiy/Group%202056.svg',
        detail: 'Our home furniture and décor brand is a one-stop shop for all home, garden and office furniture and décor. Our products suit every room, taste, and budget, whether you’re furnishing an entire house or just adding a touch of colour with décor. Our showrooms offer a distinct, sophisticated, and timeless style.\n' +
            '\n' +
            'We are among the top home and office furniture retailers in Tanzania. We started in 2016 and rebranded into GSM Home as a part of GSM Group in 2018.',
        id: 'furniture',
        brands: [
            {
                name: 'LIVING',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeihwdop2jsizwqfbr223jibk3tnhbja73iuyfdqlhqgmwixc5waige/Group%201521.svg'
            },
            {
                name: 'DINING',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeidqyynpn6xaohbvdbiw43c3hgj6i4e5s5rq6lxrwz2qrfjqgd3c4m/Group%201524.svg'
            },
            {
                name: 'BEDROOM',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeiedojz6vhviu2opa4pshgtpgkysrdocywj7u36nggbo44lundquta/Group%201527.svg'
            },
            {
                name: 'GARDEN',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeiewkuzmw5sjz43bqncigahgtizvux5ptavzdo2yqgowshql3kengi/Group%201530.svg'
            },
            {
                name: 'KIDS',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeiexdfcqfeptseynwwjgrinxzky5nmon2kbmdi4gnxf7n7u76wktoy/Group%201533.svg'
            },
            {
                name: 'DECOR',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeibxpk7cqcuxolpzguvma7ym6ukvppnxciasf6hrxqxssydljss7c4/Group%201539.svg'
            },
            {
                name: 'KITCHEN',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeicn4ijcxlojjmiluprdooyiqyjylcfetfxxjli2q75spsx36ropke/Group%201542%20(1).svg'
            },
            {
                name: 'LINEN',
                bg: '#FFDA00',
                image: 'https://ipfs.io/ipfs/bafybeifydntitub6646xp6zvepxnj5n3m5mntpljka63wmiexgzw2nak7e/Group%201738.svg'
            }
        ]
    }
]

export async function getRetailCompanies() {
    const response = await httpGetRequest('/retail-companies?populate[logo][fields]=url&populate[href][fields]=path&populate[image][fields]=url&populate[brands][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}} = x ?? {};
        const {logo, brands, detail, image, title, href} = attributes;
        return {
            key: x?.id,
            detail,
            logo: fileUrl(logo?.data?.attributes?.url),
            image: fileUrl(image?.data?.attributes?.url),
            title,
            id: pathUrl(href?.data?.attributes?.path),
            brands: itOrEmptyList(brands?.data).map(x => {
                const {attributes = {}} = x;
                const {name, bg, image} = attributes;
                return {
                    name,
                    bg,
                    image: fileUrl(image?.data?.attributes?.url)
                }
            }).reduce((a, b) => [b, ...a], href?.data?.attributes?.path?[{
                name: '',
                bg: 'transparent',
                height: 60,
                width: 300,
                image: 'https://cms.gsmgroup.africa/uploads/explore_more_icon_8037d9ef1f.svg'
            }]:[])
        };
    });
}