import {useEffect, useState} from "react";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {getDepartments} from "../services/departments";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {ReactComponent as LogisticIcon} from "../assets/svgs/transport_logistic_icon.svg";
import {ReactComponent as BuildingIcon} from "../assets/svgs/building_material_icon.svg";
import {ReactComponent as ElectronicIcon} from "../assets/svgs/electronic_appliances_icon.svg";
import {ReactComponent as FashionIcon} from "../assets/svgs/fashio_sports_icon.svg";
import {ReactComponent as FoodIcon} from "../assets/svgs/food_beverage_icon.svg";
import {ReactComponent as FurnitureIcon} from "../assets/svgs/home_office_furniture_icon.svg";
import {ReactComponent as RealEstateIcon} from "../assets/svgs/real_estate_icon.svg";
import {ReactComponent as TrucksSpareIcon} from "../assets/svgs/trucks_spare_parts_icon.svg";
import {BodyLarge} from "../../core/components/BodyLarge";
import {DEPARTMENT_SLAGS} from "../models/departments.mjs";
import {gsmBlackColor, gsmGreyColor, gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {DisplayLarge} from "../../core/components/DisplayLarge";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {Link} from "react-router-dom";


function IconMap({slag}) {
    const height = 36;
    switch (`${slag}`.toLowerCase()) {
        case DEPARTMENT_SLAGS.building.toLowerCase():
            return <BuildingIcon height={height}/>;
        case DEPARTMENT_SLAGS.food.toLowerCase():
            return <FoodIcon height={height}/>;
        case DEPARTMENT_SLAGS.fashion.toLowerCase():
            return <FashionIcon height={height}/>;
        case DEPARTMENT_SLAGS.furniture.toLowerCase():
            return <FurnitureIcon height={height}/>;
        case DEPARTMENT_SLAGS.realEstate.toLowerCase():
            return <RealEstateIcon height={height}/>;
        case DEPARTMENT_SLAGS.trucksSpare.toLowerCase():
            return <TrucksSpareIcon height={height}/>;
        case DEPARTMENT_SLAGS.electronics.toLowerCase():
            return <ElectronicIcon height={height}/>;
        case DEPARTMENT_SLAGS.transport.toLowerCase():
            return <LogisticIcon height={height}/>;
        default:
            return <></>
    }
}

function HomeDepartmentButtons({selected, onSelect = _ => null, departments = []}) {
    const style = {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
    };
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    const isSmallMedium = getScreenType() === SCREEN_WIDTH.MEDIUM;
    const itemStyle = x => ({
        backgroundColor: selected?.slag === x?.slag ? gsmBlackColor : gsmRedColor,
        borderRadius: 13,
        padding: '0px 16px',
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: isSmallScreen ? '38%' : isSmallMedium ? '27%' : 242,
        height: 86,
        cursor: "pointer",
    });
    return (
        <>
            {
                getScreenType() !== SCREEN_WIDTH.EXPANDED && <>
                    <div style={{
                        ...style, ...{
                            overflow: 'scroll',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                        }
                    }}>
                        {itOrEmptyList(departments).map((x, i) => {
                            return (
                                <div
                                    onClick={() => onSelect(x)}
                                    style={itemStyle(x)}
                                    key={'tosddsp' + i}>
                                    <IconMap slag={x?.slag}/>
                                    <WhiteSpace width={8}/>
                                    <BodyLarge color={'#fff'}>{x?.name}</BodyLarge>
                                </div>
                            );
                        })}
                    </div>
                </>
            }
            {
                getScreenType() === SCREEN_WIDTH.EXPANDED && <>
                    <div style={style}>
                        {itOrEmptyList(departments).slice(0, 4).map((x, i) => {
                            return (
                                <div
                                    onClick={() => onSelect(x)}
                                    style={itemStyle(x)}
                                    key={'top' + i}>
                                    <IconMap slag={x?.slag}/>
                                    <WhiteSpace width={8}/>
                                    <BodyLarge color={'#fff'}>{x?.name}</BodyLarge>
                                </div>
                            );
                        })}
                    </div>
                    <div style={style}>
                        {itOrEmptyList(departments).slice(4).map((x, i) => {
                            return (
                                <div
                                    onClick={() => onSelect(x)}
                                    style={itemStyle(x)}
                                    key={'bottom' + i}>
                                    <IconMap slag={x?.slag}/>
                                    <WhiteSpace width={8}/>
                                    <BodyLarge color={'#fff'}>{x?.name}</BodyLarge>
                                </div>
                            );
                        })}
                    </div>
                </>
            }
        </>
    );
}

function DepartmentBodyImages({features, onPress = _ => null}) {
    const [rows, setRows] = useState([[]]);
    useEffect(() => {
        const m = itOrEmptyList(features).reduce((a, b) => {
            const last = [...a].pop()
            if (Array.isArray(last)) {
                if (last.length < 2) {
                    last.push(b);
                    return [...a];
                } else {
                    return [...a, [b]];
                }
            } else {
                return [...a, [b]];
            }
        }, [[]]);
        setRows(m);
    }, [features]);
    return (
        <div style={{display: "flex", flexDirection: "column", height: '100%'}}>
            {rows.map((x, i) => {
                return (
                    <div
                        style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', flexGrow: 1}}
                        key={'hbi' + i}
                    >
                        {x.map((y, i) => {
                            return (
                                <div onClick={() => onPress(y)} key={'hbi_i' + i}
                                     style={{flexGrow: 1, margin: 2, cursor: "pointer"}}>
                                    <BackgroundImage title={y?.name} source={y?.image}/>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}

function HomeDepartmentBody({department}) {
    const isSMallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                minHeight: isSMallScreen && itOrEmptyList(department?.features).length < 4 ? '60vh' : '100vh'
            }}>
                <div style={{flexGrow: 1, margin: 5}}>
                    <DepartmentBodyImages features={itOrEmptyList(department?.features)}/>
                </div>
                {
                    !isSMallScreen && <>
                        <div style={{width: '35%', display: 'flex', flexDirection: 'column'}}>
                            <div style={{
                                flexGrow: 1,
                                margin: '5px 0',
                                backgroundColor: gsmBlackColor,
                                padding: 16,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8
                            }}>
                                <BodyLarge color={gsmWhiteColor}>WELCOME TO</BodyLarge>
                                <WhiteSpace height={16}/>
                                <DisplayLarge textAlign={'center'} color={gsmWhiteColor}>{department?.title}</DisplayLarge>
                                <WhiteSpace height={16}/>
                                <HeadlineSmall color={gsmGreyColor}>Part of GSM Group</HeadlineSmall>
                                <WhiteSpace height={16}/>
                                <Link
                                    to={'/companies/' + department?.id?.split('/')?.pop()}
                                    style={{backgroundColor: gsmRedColor, padding: 16, display: 'block'}}>
                                    <BodyLarge color={gsmWhiteColor}>EXPLORE MORE </BodyLarge>
                                </Link>
                                <WhiteSpace height={16}/>
                            </div>
                            <div style={{
                                margin: '5px 0',
                                padding: '24px 16px',
                                backgroundColor: gsmRedColor,
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8
                            }}>
                                {
                                    itOrEmptyList(department?.links).map((x, index) => {
                                        return (
                                            <div key={index + 'department'}>
                                                <a style={{textDecoration: 'inherit', color: 'inherit'}} href={x?.href}>
                                                    <BodyLarge color={gsmWhiteColor}>{x?.name}</BodyLarge>
                                                </a>
                                                <WhiteSpace height={8}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
            {
                isSMallScreen && <>
                    <WhiteSpace height={16}/>
                    <Link to={'/companies/' + department?.id}
                          style={{backgroundColor: gsmRedColor, padding: 16, display: 'block'}}>
                        <BodyLarge color={gsmWhiteColor}>EXPLORE MORE </BodyLarge>
                    </Link>
                    <WhiteSpace height={16}/>
                </>
            }
        </>
    );
}

export function HomeDepartments({onlyTitle = false}) {
    const [selected, setSelected] = useState();
    const [departments, setDepartments] = useState([]);
    useEffect(() => {
        getDepartments()
            .then(value => {
                setDepartments(value);
                setSelected(itOrEmptyList(value)[0]);
            })
            .catch(reason => {
            });
    }, [])
    return (
        <>
            <WhiteSpace height={16}/>
            <HomeDepartmentButtons
                departments={departments} selected={onlyTitle ? undefined : selected}
                onSelect={setSelected}
            />
            {onlyTitle === false && <WhiteSpace height={24}/>}
            {onlyTitle === false && <HomeDepartmentBody department={selected}/>}
        </>
    );
}
