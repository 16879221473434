import TruckImage from '../assets/pngs/truck.png';
import PersonInBlue from '../assets/pngs/person_in_blue.jpg';
import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl} from "../../core/services/utils.mjs";

export async function getHomeHeaderImages() {
    const response = await httpGetRequest('/home-header?populate[left_image][fields]=url&populate[right_image][fields]=url');
    const {attributes = {}} = response?.data ?? {};
    const {left_image, right_image} = attributes;
    return {
        left_image: fileUrl(left_image?.data?.attributes?.url) ?? TruckImage,
        right_image: fileUrl(right_image?.data?.attributes?.url) ?? PersonInBlue
    }
}