import {useLocation} from "react-router-dom";
import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Footer from "../../core/components/Footer";
import {useEffect, useState} from "react";
import Container from "../../core/components/Container";
import {LabelSmall} from "../../core/components/LabelSmall";
import {BodyLarge} from "../../core/components/BodyLarge";
import {HeadlineMedium} from "../../core/components/HeadlineMedium";
import {useScrollUp} from "../../core/hooks/scrollUp";

export function PressAndMediaView() {
    const [data, setData] = useState();
    const location = useLocation();
    useEffect(() => {
        if (location?.state) {
            setData(location.state);
        } else {
            location.replace('/');
        }
    }, [location])
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <WhiteSpace height={24}/>
            <Container>
                <HeadlineMedium>{data?.title}</HeadlineMedium>
                <WhiteSpace height={16}/>
                <LabelSmall>#{data?.tag}</LabelSmall>
                <WhiteSpace height={24}/>
                {/*<div style={{width: '100%'}}>*/}
                <img
                    style={{borderRadius: 8}}
                    alt={''} width={'100%'}
                    src={data?.image}/>
                {/*</div>*/}
                <WhiteSpace height={16}/>
                <BodyLarge>{data?.detail}</BodyLarge>
            </Container>
            {/*<HomeDepartments onlyTitle={true}/>*/}
            <WhiteSpace height={58}/>
            <Footer/>
        </div>
    )
}