import React, {useEffect, useState} from "react";
import {AppHeader} from "../../core/components/AppHeader";
import {AboutMenu} from "../components/AboutMenu";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import {getGSMAboutCareer} from "../services/about_career";
import {gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {TitleLarge} from "../../core/components/TitleLarge";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {BodyLarge} from "../../core/components/BodyLarge";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import Container from "../../core/components/Container";
import Paragraph from "../../core/components/Paragraph";
import {TitleMedium} from "../../core/components/TitleMedium";
import {Link} from "react-router-dom";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";

function FaqItem({index, value}) {
    return (
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
            <div style={{
                padding: '16px',
                minWidth: 50,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                border: 'solid',
                borderColor: gsmRedColor,
                borderWidth: .5,
                background: index % 2 === 0 ? gsmWhiteColor : gsmRedColor
            }}>
                <TitleMedium color={index % 2 === 0 ? gsmRedColor : gsmWhiteColor}>{index + 1}</TitleMedium>
            </div>
            <div style={{
                padding: '16px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                border: 'solid',
                borderColor: gsmRedColor,
                borderWidth: .5,
                flexGrow: 1,
                background: index % 2 === 0 ? gsmRedColor : gsmWhiteColor
            }}>
                <TitleMedium color={index % 2 === 0 ? gsmWhiteColor : gsmRedColor}>{value}</TitleMedium>
            </div>
        </div>
    )
}

function PositionItem({index, value}) {
    return (
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', padding: 8, background: gsmWhiteColor}}>
            <div style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}>
                <TitleMedium color={gsmRedColor}>{value?.title}</TitleMedium>
                <BodyLarge>{value?.description}</BodyLarge>
            </div>
            <div>
                <Link to={value?.application_link} target={"_blank"}>
                    <div style={{
                        padding: '24px',
                        // height: 10,
                        background: gsmRedColor,
                        marginRight: 16,
                        marginLeft: 16,
                        cursor: "pointer"
                    }}>

                        <TitleMedium color={gsmWhiteColor}>
                            APPLY
                        </TitleMedium>
                    </div>
                </Link>
            </div>
        </div>
    )
}

function CareerHeaderText({title, value}) {
    return (
        <div style={{
            minWidth: '200px',
            maxWidth: '350px',
            minHeight: '150px',
            maxHeight: '250px',
            background: gsmRedColor,
            opacity: .8,
            margin: 24,
            padding: 16,
            textAlign: 'center',
            borderRadius: 8,
        }}>
            <TitleLarge color={gsmWhiteColor}>
                {title}
            </TitleLarge>
            <WhiteSpace height={16}/>
            {
                Array.isArray(value) ? value.map(x => {
                    return (
                        <div key={x}>
                            <BodyLarge color={gsmWhiteColor}>
                                {x}
                            </BodyLarge>
                        </div>
                    )
                }) : <BodyLarge color={gsmWhiteColor}>
                    {value}
                </BodyLarge>
            }
        </div>
    )
}

function CareerHeader({career}) {
    return (
        <div style={{
            backgroundImage: `url('${career?.header}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '85vh',
            width: '100%',
            borderRadius: 0,
            display: 'flex',
            flexDirection: getScreenType() === SCREEN_WIDTH.COMPACT ? 'column' : 'row',
            flexWrap: 'wrap',
            justifyContent: "center",
            alignItems: 'center',
        }}>
            <CareerHeaderText
                title={'What We Are About'} value={career?.about}/>
            <CareerHeaderText
                title={'Join Us'}
                value={itOrEmptyList(career?.positions).slice(0, 4).map(x => x?.title)}/>
        </div>
    )
}

function CareerFooter({career}) {
    return (
        <div style={{
            backgroundImage: `url('${career?.footer}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
            borderRadius: 0,
            paddingTop: 24,
            paddingBottom: 24,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Container>
                {
                    itOrEmptyList(career?.positions).map((x, index) => {
                        return (
                            <div key={'ti76ruyj' + index}>
                                <PositionItem value={x} index={index}/>
                                <WhiteSpace height={16}/>
                            </div>
                        )
                    })
                }
            </Container>
        </div>
    )
}

function CareerMiddle({career}) {
    return (
        <div style={{
            backgroundImage: `url('${career?.middle}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '85vh',
            width: '100%',
            borderRadius: 0,
        }}/>
    )
}

export function AboutCareerPage() {
    const [career, setCareer] = useState({});

    useEffect(() => {
        getGSMAboutCareer().then(setCareer).catch(_ => {
        });
    }, []);
    // useScrollUp();

    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <AboutMenu activeLink={'/about_career'}/>
            {
                career && <>
                    <div style={{minHeight: '85vh', paddingTop: 58}}>
                        <CareerHeader career={career}/>
                    </div>
                    <WhiteSpace height={48}/>
                    <Container>
                        <div style={{textAlign: 'center'}}>
                            <HeadlineLarge fontWeight={900} textAlign={'center'} color={gsmRedColor}>
                                Working At GSM
                            </HeadlineLarge>
                            <WhiteSpace height={16}/>
                            <Paragraph>
                                {career?.working_at_gsm}
                            </Paragraph>
                        </div>
                    </Container>
                    <div style={{minHeight: '85vh', paddingTop: 58}}>
                        <CareerMiddle career={career}/>
                    </div>
                    <WhiteSpace height={48}/>
                    <Container>
                        <div style={{textAlign: 'center'}}>
                            <HeadlineLarge fontWeight={900} textAlign={'center'} color={gsmRedColor}>
                                Why you would love working at GSM
                            </HeadlineLarge>
                            <WhiteSpace height={48}/>
                            <Paragraph>
                                {
                                    itOrEmptyList(career?.faqs).map((x, index) => {
                                        return (
                                            <div key={'ti76ruyj' + index}>
                                                <FaqItem value={x?.value} index={index}/>
                                            </div>
                                        )
                                    })
                                }
                            </Paragraph>
                        </div>
                    </Container>
                    <div style={{minHeight: '85vh', paddingTop: 58}}>
                        <CareerFooter career={career}/>
                    </div>
                    <WhiteSpace height={48}/>
                </>
            }
            <NewsUpdates/>
            <Footer/>
        </div>
    )
}