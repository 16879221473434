import {getScreenType, SCREEN_WIDTH} from "../services/layout";

export function HeadlineSmall({children, color, fontWeight = 500, textAlign='justify'}) {
    return (
        <span style={{
            lineHeight: '1.05em',
            fontFamily: 'Open Sans',
            textAlign,
            fontSize: getScreenType() === SCREEN_WIDTH.COMPACT ? '1.0em' : '1.5em',
            display: 'block',
            color,
            fontWeight,
        }}>{children}</span>
    );
}
