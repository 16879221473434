import {useEffect, useRef, useState} from "react";
import {gsmBlackColor} from "../services/colors.mjs";
import {ReactComponent as PlayIcon} from "../assets/svg/play_icon.svg";
import {ReactComponent as PauseIcon} from "../assets/svg/pause_icon.svg";
const PosterImage = require('../../../assets/images/gsm_video_poster.jpeg')

let timeInterval;

export function GSMVideoPlayer({src, style, cRef}) {
    const videoRef = useRef();
    const [playing, setPlaying] = useState(false);
    const [showControl, setShowControl] = useState(true);

    useEffect(() => {
        timeInterval = setInterval(() => {
            if (playing) {
                setShowControl(false);
            } else {
                setShowControl(true);
            }
        }, 5000);
        return () => {
            clearTimeout(timeInterval);
        }
    }, [playing, src]);

    function playPause() {
        try {
            const video = videoRef.current;
            // const isPlaying = video?.currentTime > 0 && !video?.paused && !video?.ended
            //     && video?.readyState > video?.HAVE_CURRENT_DATA;
            //
            // if (!isPlaying) {
            //     setPlaying(true);
            //     video?.play();
            // } else {
            //     setPlaying(false);
                video?.pause();
                window.open('https://youtube.com/@gsmgroupofcompanies', '_blank');
            // }
            // // if (playing === true) {
            // //     setPlaying(false);
            // //     videoRef?.current?.pause();
            // // } else {
            // //     setPlaying(true);
            // //     videoRef?.current?.play();
            // // }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div ref={cRef} onMouseMove={(e) => {
            setShowControl(true);
        }} style={{
            // maxHeight: 500,
            // minHeight: 300,
            maxHeight: 600,
            // height: '70vh',
            ...style,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: "relative",
        }}>
            <video
                ref={videoRef}
                // loop
                onEnded={_ => {
                    setPlaying(false);
                }}
                style={{
                    // aspectRatio: 16 / 9,
                    width: '100%',
                    maxHeight: 590,
                    // height: '65vh',
                    // position: 'absolute',
                    // top: 0,
                    // bottom: 0,
                    // left: 0,
                    // right: 0
                }}
                onClick={playPause}
                src={src ?? ''}
                poster={PosterImage}
            />
            <div
                onClick={playPause}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <div style={{
                    width: 80,
                    height: 80,
                    borderRadius: 80,
                    background: gsmBlackColor,
                    opacity: 0.8,
                    justifyContent: "center",
                    alignItems: 'center',
                    display: showControl ? "flex" : "none"
                }}>
                    {playing ? <PauseIcon height={30} width={30}/> : <PlayIcon height={30} width={30}/>}
                </div>
            </div>
        </div>
    );
}