import React, {useEffect, useState} from "react";
import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Container from "../../core/components/Container";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import {compose, itOrEmptyList, prepareSplitArrayTo} from "../../core/services/utils.mjs";
import {getGSMManufacturing} from "../services/manufacturing";
import {HeadItem} from "../components/HeadItem";
import {BodyItem} from "../components/BodyItem";
import {useScrollUp} from "../../core/hooks/scrollUp";


export function ManufacturingPage() {
    const [data, setData] = useState();
    const [malls, setMalls] = useState([]);

    useEffect(() => {
        getGSMManufacturing().then(value => {
            setData(value);
            const getMallChunks = compose(itOrEmptyList, prepareSplitArrayTo(2));
            setMalls(getMallChunks(value?.malls));
        }).catch(() => {
        });
    }, []);
    useScrollUp();
    return (
        <>
            <AppHeader/>
            <WhiteSpace height={48}/>
            <Container>
                <HeadItem logo={data?.logo} detail={data?.detail}/>
                {
                    malls?.map((x, ix) => {
                        return (
                            <div key={`${ix}_manu_item`} style={{flexDirection: 'row', width: '100%', display: 'flex'}}>
                                {
                                    x?.map((item, index) => {
                                        return (
                                            <div style={{flexGrow: 1, margin: 10}} key={`${index}_manu_item_inner`}>
                                                <BodyItem name={item?.name} image={item?.image}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </Container>
            <NewsUpdates/>
            <Footer/>
        </>
    )
}
