import React from "react";
import Container from "../../core/components/Container";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {gsmRedColor} from "../../core/services/colors.mjs";
import NewsUpdates from "../../core/components/NewsUpdates";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";


export function ContactNewsAndUpdates() {
    return (
        <div>
            <Container>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 100,
                }}>
                    <HeadlineLarge fontWeight={600}>Stay</HeadlineLarge>
                    <WhiteSpace width={16}/>
                    <HeadlineLarge fontWeight={600} color={gsmRedColor}>Updated</HeadlineLarge>
                </div>
            </Container>
            <div>
                <NewsUpdates/>
            </div>
        </div>
    )
}