import React from "react";
import {ReactComponent as Twitter} from "../assets/svg/twitter.svg";
import {ReactComponent as FacebookIcon} from "../assets/svg/facebook.svg";
import {ReactComponent as LinkedIn} from "../assets/svg/linkedin.svg";
import {ReactComponent as Instagram} from "../assets/svg/instagram.svg";
import {ReactComponent as Youtube} from "../assets/svg/youtube.svg";

export function SocialIcons({color='#fff'}) {
    const socialIcons = [
        {
            link: 'https://twitter.com/Gsmgroup_',
            icon: <Twitter fill={color} style={{color}} color={color}/>
        },
        {
            link: 'https://linkedin.com/company/gsm-group-of-companies/',
            icon: <LinkedIn color={color}/>
        },
        {
            link: 'https://www.facebook.com/gsmcompaniestanzania/',
            icon: <FacebookIcon color={color}/>
        },
        {
            link: 'https://www.instagram.com/gsmgroupofcompanies/',
            icon: <Instagram color={color}/>
        },
        {
            link: 'https://www.youtube.com/channel/UCLbfl_eyjCNZGK10WpYJRkg',
            icon: <Youtube color={color}/>
        },
    ]
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
            {
                socialIcons.map((value, index) => {
                    return (
                        <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{margin: 20}}
                            key={index + 'socialIcons'}
                            href={value.link}>{value.icon}
                        </a>
                    )
                })
            }
        </div>
    );
}
