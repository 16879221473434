import Container from "../../core/components/Container";
import {Center} from "../../core/components/Center";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {DisplayMedium} from "../../core/components/DisplayMedium";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {gsmBgGreyColor, gsmGreyColor, gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {useEffect, useState} from "react";
import {getGSMLatestNews} from "../services/press_and_media.mjs";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {ExpandableFold} from "../../core/components/ExpandableFold";
import {BodyLarge} from "../../core/components/BodyLarge";
import {LabelMedium} from "../../core/components/LabelMedium";
import moment from "moment";
import {Link} from "react-router-dom";

export function PressAndMedia() {
    const [latestNews, setLatestNews] = useState([]);

    useEffect(() => {
        getGSMLatestNews().then(setLatestNews).catch(_ => null);
    }, []);

    return (
        <div style={{backgroundColor: gsmBgGreyColor}}>
            <Container>
                {itOrEmptyList(latestNews).length > 0 && <Center>
                    <div style={{
                        maxWidth: 1000,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: "column",
                        display: "flex",
                        paddingTop: 48,
                        paddingBottom: 48
                    }}>
                        <HeadlineSmall fontWeight={400}>PRESS & MEDIA</HeadlineSmall>
                        <WhiteSpace height={10}/>
                        <DisplayMedium color={gsmRedColor} fontWeight={900}>GSM GROUP NEWS</DisplayMedium>
                        <WhiteSpace height={24}/>
                        <div style={{width: '100%'}}>
                            <ExpandableFold items={
                                latestNews.map((news, index) => {
                                    return (
                                        <Link to={news?.link} target={'_blank'} key={index + 'news'}
                                              style={{height: latestNews.length === 1 ? 500 : 300, display: 'block'}}>
                                            <BackgroundImage
                                                style={{height: '100%'}}
                                                source={news?.image}>
                                                <>
                                                    <BodyLarge color={gsmWhiteColor}>{news?.title}</BodyLarge>
                                                    <WhiteSpace height={8}/>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <LabelMedium
                                                            color={gsmGreyColor}>{moment(news?.createdAt ?? new Date()).format('MMM DD, YYYY')}</LabelMedium>
                                                        <WhiteSpace width={8}/>
                                                        <LabelMedium color={gsmGreyColor}>{news?.readTimeInMin} min
                                                            read</LabelMedium>
                                                        <WhiteSpace width={8}/>
                                                        <LabelMedium color={gsmGreyColor}>#{news?.tag}</LabelMedium>
                                                    </div>
                                                    <WhiteSpace height={8}/>
                                                </>
                                            </BackgroundImage>
                                        </Link>
                                    );
                                })
                            }/>
                        </div>
                        {/*<HeaderNavItem name={'EXPLORE MORE'} active color={gsmRedColor} link={'https://www.youtube.com/@gsmgroupofcompanies'}/>*/}
                        <WhiteSpace height={54}/>
                    </div>
                </Center>}
            </Container>
        </div>
    );
}
