import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultData = [
    {
        logo: 'https://ipfs.io/ipfs/bafybeiasu4zw464scktutmhndvfazakxz3mm4awze5flbk66ltjnpfptty/galcon_icon.png',
        image: 'https://ipfs.io/ipfs/bafybeigaauocf7dmxep7v3nbxom6x5q7gn3cjpfd3vdla323kyeqf2gywe/WhatsApp%20Image%202023-08-07%20at%2012.45.36.jpeg',
        name: 'GALCO LTD',
        detail: 'GALCO Ltd is our trusted third-party logistics (3PL) provider that plays a crucial role in efficiently distributing goods across Eastern, Southern, and Central Africa. Currently, our services extend to Tanzania, Zambia, Mozambique, Malawi, DRC, Rwanda, Burundi, South Sudan, and South Africa.\n' +
            '\n' +
            'To facilitate smooth operations, GALCO boasts a robust fleet of over 700 trucks and 100 light trucks. Additionally, we possess 100 brand new containers that allow us to transport client cargo to and from shipping line locations. When required, we can also provide flat-bed and drop-side trailers.\n' +
            '\n' +
            'To ensure the safety and security of our fleet, we employ state-of-the-art satellite tracking, which enables us to monitor our trucks 24/7. Furthermore, our commitment to excellence includes frequent road patrols that help us optimize turnaround times.',
        action: {
            name: 'CALL US TODAY',
            link: '#'
        },
        services: [

        ]
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeiaxihylkzyxiq2j6v7mlvncbezt6tqhrpel22b24zqyl3wlwx42sy/GALCO%20ICD%20Logo.png',
        image: 'https://ipfs.io/ipfs/bafybeigw22e7bxnpv4rsdc7wqpa6pu7boghhxqq3kvhsfhmmocfz2tasre/shutterstock_552634261%20(1).png',
        name: 'Internal Container Depot Services',
        detail: 'GALCO Inland Container Depot (ICD) owns more than 20 warehouses (both bonded and free) and offers a full array of services, from bulk and refrigerated container storage to palletising and stretch-wrapping local and transit containers. Our newest licence for handling Dangerous Goods allows us to offer a range of solutions for any kind of cargo coming into the market.',
        action: {
            name: 'CALL US TODAY',
            link: '#'
        },
        services: [
            {
                name: 'Stuffing and Stripping Containers',
                svg: 'https://ipfs.io/ipfs/bafybeibbx6n525xdqzuqecefyqudbmrr634yqn5lk2kjumazrdiczj7blm/Group%201453.svg'
            },
            {
                name: 'Cargo Dispatch and Delivery',
                svg: 'https://ipfs.io/ipfs/bafybeihxhrzxovlvv3j5x7yizti3zq6xzwukbe6inuxgmatobnliwiqjmy/Group%201451.svg'
            },
            {
                name: 'Empty Container Depot',
                svg: 'https://ipfs.io/ipfs/bafybeigeq7viq57qvaj3p32ehzc5kvebydjtj2zhztaucgnvtsu57cn2he/Group%201440.svg'
            },
            {
                name: 'Transfer Operation To Port',
                svg: 'https://ipfs.io/ipfs/bafybeihxhrzxovlvv3j5x7yizti3zq6xzwukbe6inuxgmatobnliwiqjmy/Group%201451.svg'
            },
            {
                name: 'Heavy Lift And Oversize Cargo',
                svg: 'https://ipfs.io/ipfs/bafybeicgrccs4q2gkau5imz6uwa6m5demspjlbhdlz2wuyx7s67azar2hi/Group%201459.svg'
            }
        ]
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeiaxihylkzyxiq2j6v7mlvncbezt6tqhrpel22b24zqyl3wlwx42sy/GALCO%20ICD%20Logo.png',
        image: 'https://ipfs.io/ipfs/bafybeihlskwnifc6iulcw5r542cq3dwueloxhqy2fmlpwpsz7xdsum674i/shutterstock_1097589623.jpg',
        name: 'GALCO ICDV',
        detail: 'Our ICDV (Inland Container Deport for Vehicles) serve as the port extension where vehicles are temporary stored before being handled to their respective owners. GALCO ICDV is audited and authorised by both TPA and TRA. Our ICDV can keep up to 3,000 vehicles at once, making it one of the largest vehicles warehouses in Tanzania.',
        action: {
            name: 'CALL US TODAY',
            link: '#'
        },
        services: []
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeig4lts2q55sxop6ms7edxtkvn6gp5pd2zn7b5uyh5mduu7u7ng6fm/GALCO%20CFS%20Logo.svg',
        name: 'Container Freight Station',
        image: 'https://ipfs.io/ipfs/bafybeiantj5owco4tjhmbzjkfqpesfbes7gesub5ujirnk2ervsb2h2ntm/Rectangle%202678%20(1).png',
        detail: 'GALCO Container Freight Station (CFS) handles the exportation of local and transit cargo in accordance with Tanzania Revenue and Ports Authorities for international road, sea, and air freights. Our yard is dedicated to handling agricultural produce, minerals and machinery/equipment. Our services include stuffing and stripping of containers, consolidation of LCL (less than a container load) cargo and temporary storage of cargo. ',
        action: {
            name: 'CALL US TODAY',
            link: '#'
        },
        services: [
            {
                name: 'International Road, Air and Sea Freight',
                svg: 'https://ipfs.io/ipfs/bafybeihxhrzxovlvv3j5x7yizti3zq6xzwukbe6inuxgmatobnliwiqjmy/Group%201451.svg'
            },
            {
                name: 'Warehousing and Distribution',
                svg: 'https://ipfs.io/ipfs/bafybeigeq7viq57qvaj3p32ehzc5kvebydjtj2zhztaucgnvtsu57cn2he/Group%201440.svg'
            },
            {
                name: 'Port and Border Clearance',
                svg: 'https://ipfs.io/ipfs/bafybeid4tqr45isysmhpvpk57wxkvr46gjnr6bqzu3lapm2jtcpk4bbni4/Path%209569.svg'
            },
            {
                name: 'Handling Equipment',
                svg: 'https://ipfs.io/ipfs/bafybeicgrccs4q2gkau5imz6uwa6m5demspjlbhdlz2wuyx7s67azar2hi/Group%201459.svg'
            },
            {
                name: 'Loose Container Load Handling and Clearance',
                svg: 'https://ipfs.io/ipfs/bafybeibbx6n525xdqzuqecefyqudbmrr634yqn5lk2kjumazrdiczj7blm/Group%201453.svg'
            },
            {
                name: 'Dangerous Goods',
                svg: 'https://ipfs.io/ipfs/bafybeidi5ytyiw5sw4wugpon3bwr7yl6dhdsa334reibbkqirxg64gohsa/Group%201455.svg'
            }
        ]
    },
    {
        logo: 'https://ipfs.io/ipfs/bafybeiabrbvmgtubmfy57xwp3w7rt5vazomol5s2rc7igh3b5ou5zklm6a/Group%202052.svg',
        image: 'https://ipfs.io/ipfs/bafybeigzl2ufa7wkpjkaerfjdlbqxbv4la6pbg3hmcb2furlyi6qlxcfhm/shutterstock_670636564%20(1).png',
        name: 'Insurance Services',
        detail: 'GALCO Insurance Brokers Ltd is an Insurance Brokerage firm in Tanzania registered by the Commissioner of Insurance. The company is dedicated not only to providing Insurance brokerage services but also to transform people’s minds to positively accept insurance in Tanzania and East Africa at large as per the enactment of the Insurance Act No. 18 of 1996.\n' +
            '\n' +
            'As a broker we will help you asses and identify your corporate / individual business risks and to help you with various mitigation measures. GALCO insurance broker has grown into a well-respected insurance brokerage services provider. We aim at establishing a successful partnership with our clients, staff members and insurance companies that respect the interests and goals of each party. We have vast experience in providing quality insurance packages serving the whole of Tanzania. Our company currently serves the insurance needs of over 500 individuals and families as well as over 10 different private sector corporations/companies.',
        action: {
            name: 'CALL US TODAY',
            link: 'tel:0762188188'
        },
        services: [
            {
                name: 'Medical (Health)',
                svg: 'https://ipfs.io/ipfs/bafybeibc7vnqsuue27mymrrxg32k6fklodurxglhvbr4xwpnhweln7425e/Group%201417.svg',
            },
            {
                name: 'Motor Vehicle',
                svg: 'https://ipfs.io/ipfs/bafybeigkmcdjoz4gy7qpbrdcyjiqkcomukk7ty5dz4kggnlr5id2w6s7vq/Group%201418.svg'
            },
            {
                name: 'Accident Cover',
                svg: 'https://ipfs.io/ipfs/bafybeibwssijbi6omcduxdet65rdj3wd5chb263zaxi55hryqkvifalr6q/Group%201423.svg'
            },
            {
                name: 'Domestic Package',
                svg: 'https://ipfs.io/ipfs/bafybeicoudggwtzpgsnfkmmoc4cg4z4y6usefyqiueha6mxi4rycnvquxe/Group%201425.svg'
            },
            {
                name: 'Fire Insurance',
                svg: 'https://ipfs.io/ipfs/bafybeiahwupf7vxg25y444nvhljvwmunhiqcvu3567xbd6ainsamfhavmi/Group%20118.svg'
            },
            {
                name: 'Burglary (Theft)',
                svg: 'https://ipfs.io/ipfs/bafybeidje4pthwrwa7psuue43fcscshznpjpxmeod5rvpljnms6zd3dl6m/Group%201433.svg'
            },
            {
                name: 'Professional Identity',
                svg: 'https://ipfs.io/ipfs/bafybeieevyetqved2gqbuu2afqndxbvk4kaonqs4blloob3ff4mflwizjm/Group%201419.svg'
            },
            {
                name: 'Fidelity Guarantee',
                svg: 'https://ipfs.io/ipfs/bafybeicmk2b3cooqzd66nt73rld66z2hvx6wxbjy25kap3vpc3nijjwxmu/Group%201421.svg'
            },
            {
                name: 'Employees Liability',
                svg: 'https://ipfs.io/ipfs/bafybeids7vrc4fr2zofb3bx2zxavkv4hzz52q4gzqdm6yqy52rhhiook64/Group%201429.svg'
            },
            {
                name: 'Money Insurance',
                svg: 'https://ipfs.io/ipfs/bafybeiclfy3v54xzlhibsp545l2rgbnfl6l6kqlzolfeods3ur4jro5ibq/Group%201435.svg'
            },
            {
                name: 'Public Liability',
                svg: 'https://ipfs.io/ipfs/bafybeidxbk4ibbfmwepppqdfwhf2mbzqegzacjn53i67343iwhzieyx7kq/Group%201428.svg'
            },
            {
                name: 'Life Assurance',
                svg: 'https://ipfs.io/ipfs/bafybeifew4qardugtef7i4avaoc4ab3bfryyzvgtaxovjpuzfyna2pq7xe/Group%201422.svg'
            },
            {
                name: 'Contractors All Risks',
                svg: 'https://ipfs.io/ipfs/bafybeibjx7ivgr5dizx5xgmxfkwvukhxuowl2mo3pce2nfqtvurjaafarq/Group%201430.svg'
            },
            {
                name: 'Goods In Transit Cover',
                svg: 'https://ipfs.io/ipfs/bafybeih2hdyhixm4txe5yhk6aciudz3rl3i4vl2s34lttnd2sks36qireu/Group%201426.svg'
            },
            {
                name: 'Electronic Equipment Cover',
                svg: 'https://ipfs.io/ipfs/bafybeihga5xhrf6ahwemzq6vtlluuitw2kdw4eru6huie55apb4pbo3lmu/Group%201437.svg'
            }
        ]
    },
];

export async function getGSMLogisticCompanyServices() {
    const response = await httpGetRequest('/logistic-companies?populate[logo][fields]=url&populate[image][fields]=url&populate[action][fields]=*&populate[services][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length===0?defaultData:itOrEmptyList(data).map(x=>{
        const {attributes={}}=x??{};
        const {logo,image,name,details,action,services} = attributes;
        return {
            logo: fileUrl(logo?.data?.attributes?.url),
            image: fileUrl(image?.data?.attributes?.url),
            name,
            detail: details,
            action,
            services: itOrEmptyList(services).map(y=>{
                return {
                    name: y?.name,
                    svg: fileUrl(y?.image?.data?.attributes?.url)
                }
            })
        };
    });
}
