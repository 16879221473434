import {HomeHeader} from "../components/HomeHeader";
import {HomeAboutUs} from "../components/HomeAboutUs";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import HomeCoreValue from "../components/HomeCoreValues";
import HomeGroupClients from "../components/HomeGroupClients";
import Footer from "../../core/components/Footer";
import React from "react";
import NewsUpdates from "../../core/components/NewsUpdates";
import HomeTeam from "../components/HomeTeam";
import {HomeDepartments} from "../components/HomeDepartments";
import {PressAndMedia} from "../components/PressAndMedia";
import {useScrollUp} from "../../core/hooks/scrollUp";

export function HomePage() {
    useScrollUp();
    return (
        <div>
            <HomeHeader/>
            <HomeDepartments/>
            <WhiteSpace height={48}/>
            <HomeAboutUs/>
            <WhiteSpace height={48}/>
            <HomeCoreValue/>
            <WhiteSpace height={48}/>
            <PressAndMedia/>
            <WhiteSpace height={48}/>
            <HomeGroupClients/>
            <WhiteSpace height={48}/>
            <HomeTeam/>
            <NewsUpdates/>
            <Footer/>
        </div>
    );
}