import {gsmRedColor} from "../services/colors.mjs";

export function MenuIcon({onShow}) {
    return (
        <>
            <div onClick={() => {
                onShow();
            }} style={{
                width: 48,
                height: 48,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
            }}>
                <div style={{
                    background: gsmRedColor,
                    height: 4,
                    width: 30,
                    marginBottom: 1.8,
                    borderRadius: 2
                }}/>
                <div style={{
                    background: gsmRedColor,
                    height: 4,
                    width: 30,
                    marginBottom: 1.8,
                    borderRadius: 2
                }}/>
                <div style={{
                    background: gsmRedColor,
                    height: 4,
                    width: 30,
                    marginBottom: 1.8,
                    borderRadius: 2
                }}/>
            </div>
        </>
    )
}