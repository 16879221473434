import {WhiteSpace} from "../../core/components/WhiteSpace";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import {Center} from "../../core/components/Center";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import Paragraph from "../../core/components/Paragraph";
import React, {useEffect, useState} from "react";
import Haier from "../assets/pngs/haier.png";
import Container from "../../core/components/Container";
import {gsmBgGreyColor, gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {getGSMCustomerTestimonies} from "../services/gsm_testimonies.mjs";
import {BodyLarge} from "../../core/components/BodyLarge";
import {getGSMAdvert} from "../services/gsm_advert.mjs";

function UserAvatar({height, src, onPress, active = false}) {
    return (
        <div onClick={onPress} style={{margin: 10}}>
            <img
                src={src}
                alt={'User'}
                style={{
                    height: height,
                    borderRadius: '50%',
                    background: gsmWhiteColor,
                    backgroundSize: 'cover',
                    border: "solid",
                    borderColor: active ? gsmRedColor : 'transparent'
                }}/>
        </div>
    )
}

export default function HomeTeam({icon}) {

    const [people, setPeople] = useState([]);
    const [advert, setAdvert] = useState();
    const [selectedPerson, selectPerson] = useState();

    useEffect(() => {
        getGSMCustomerTestimonies().then(value => {
            selectPerson(value[0]);
            setPeople(value);
        }).catch(reason => {
            console.log(reason);
        });

        getGSMAdvert().then(value => {
            setAdvert(value??{image: Haier});
        }).catch(reason => {
            console.log(reason);
        });
    }, []);

    return (
        <>
            <div style={{
                backgroundColor: gsmBgGreyColor,
                paddingTop: '30px',
                paddingBottom: '30px',
            }}>
                {/*<Container>*/}
                <div style={{width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {/*    <Center>*/}
                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        overflow: "scroll",
                        alignItems: 'center'
                    }}>
                        {
                            itOrEmptyList(people).map((person, index) => {
                                return (
                                    <UserAvatar
                                        active={selectedPerson === person}
                                        onPress={() => {
                                            selectPerson(person);
                                        }}
                                        key={index + 'UserAvatar'}
                                        height={selectedPerson === person ? 200 : 150}
                                        src={person?.image}
                                    />
                                )
                            })
                        }
                    </div>
                    {/*</Center>*/}
                </div>
                <WhiteSpace height={10}/>
                <Container>
                    {selectedPerson && <>
                        <HeadlineLarge fontWeight={900}>
                            <Center>{selectedPerson?.name}</Center>
                        </HeadlineLarge>
                        <WhiteSpace height={10}/>
                        <HeadlineSmall fontWeight={600} color={gsmRedColor}>
                            <Center>{selectedPerson?.title}</Center>
                        </HeadlineSmall>
                        <WhiteSpace height={10}/>
                        <BodyLarge>
                            <Center>{selectedPerson?.company}</Center>
                        </BodyLarge>
                        <WhiteSpace height={30}/>
                        <Center>
                            <Paragraph textAlign={'center'}>
                                {selectedPerson?.description}
                            </Paragraph>
                        </Center>
                    </>}
                </Container>
                {/*</Container>*/}
            </div>
            <WhiteSpace height={48}/>
            <Center>
                <img src={advert?.image} width={'100%'} alt={'Advert'}/>
            </Center>
        </>
    )
}
