import {HeaderNavItem} from "../../core/components/HeaderNavItem";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {GSMLogo} from "../../core/components/GSMLogo";
import {DisplayLarge} from "../../core/components/DisplayLarge";
import {DisplaySmall} from "../../core/components/DisplaySmall";
import {Center} from "../../core/components/Center";
import {ScrollDownButton} from "../../core/components/ScollDownButton";
import {gsmRedColor} from "../../core/services/colors.mjs";
import {Link} from "react-router-dom";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {MenuIcon} from "../../core/components/MenuIcon";
import React, {useEffect, useState} from "react";
import {AppMobileMenu} from "../../core/components/AppMobileMenu";
import {SocialIcons} from "../../core/components/SocialIcons";
import {getHomeHeaderImages} from "../services/home_header.mjs";

function HomeHeaderCenterItem({width}) {
    return (
        <div style={{
            ...{
                width,
                display: 'flex',
                paddingLeft: 16,
                paddingRight: 16,
                flexDirection: "column",
                alignItems: 'center',
            },
            ...getScreenType() === SCREEN_WIDTH.EXPANDED ? {
                minWidth: 300,
                maxWidth: '35%'
            } : {}
        }}>
            <WhiteSpace height={24}/>
            <Link style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} to={'/'}>
                <GSMLogo width={'20%'}/>
            </Link>
            <WhiteSpace height={16}/>
            <DisplayLarge textAlign={'center'} color={gsmRedColor}>
                PAVING THE WAY
            </DisplayLarge>
            <DisplayLarge textAlign={'center'} color={gsmRedColor}>
                TO AFRICA,
            </DisplayLarge>
            <DisplayLarge textAlign={'center'} color={gsmRedColor}>
                FOR AFRICA!
            </DisplayLarge>
            <WhiteSpace height={24}/>
            <span style={{flexGrow: 1}}/>
            <DisplaySmall textAlign={'center'}>
                WITH CUSTOMER CENTERED PRODUCTS & B2B SERVICES
            </DisplaySmall>
            <WhiteSpace height={16}/>
            <Center>
                <ScrollDownButton/>
            </Center>
        </div>
    );
}

function HomeHeaderSideItem({actions, footer, image, style = {}}) {
    return (
        <div style={{...style ?? {}, ...{width: '100%', position: 'relative'}}}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '100%'
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 48,
                    left: 16,
                    right: 16
                }}>
                {actions}
            </div>
            <div style={{
                position: 'absolute',
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 16,
                left: 16,
                right: 16
            }}>{footer}</div>
        </div>
    );
}

function ActionsWrapper({children}) {
    return (
        <div style={{
            // width: '100%',
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            // alignContent: 'space-between',
            // alignItems: 'center'
        }}>
            {children}
        </div>
    );
}

function LeftActions() {
    return (
        <ActionsWrapper>
            <HeaderNavItem link={'/about'} name={'About Us'} color={'#fff'}/>
            <WhiteSpace width={16}/>
            <HeaderNavItem link={'/companies'} name={'Our Companies'} color={'#fff'}/>
        </ActionsWrapper>
    );
}

function RightActions() {
    return (
        <ActionsWrapper>
            <HeaderNavItem link={'products'} name={'Products & Services'} color={'#fff'}/>
            <WhiteSpace width={16}/>
            <HeaderNavItem link={'contacts'} name={'Contact Us'} color={'#fff'}/>
        </ActionsWrapper>
    );
}

export function HomeHeader() {
    const [data,setData] = useState({});
    const [showMenu, setShowMenu] = useState(false);
    const isLargeScreen = getScreenType() === SCREEN_WIDTH.EXPANDED;

    useEffect(()=>{
        getHomeHeaderImages().then(value => {
            setData(value);
        }).catch(()=>{});
    },[])

    return (
        <>
            <div style={{
                height: '100vh',
                flexDirection: isLargeScreen ? 'row' : 'column',
                display: 'flex',
                flexWrap: 'nowrap'
            }}>
                {
                    !isLargeScreen && <>
                        <div style={{
                            padding: 16,
                            display: "flex",
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}>
                            <MenuIcon onShow={() => setShowMenu(true)}/>
                        </div>
                    </>
                }
                {isLargeScreen &&
                    <HomeHeaderSideItem
                        footer={<SocialIcons/>}
                        actions={<LeftActions/>}
                        image={data?.left_image}
                        style={{flexGrow: 1}}/>}
                <HomeHeaderCenterItem/>
                {isLargeScreen &&
                    <HomeHeaderSideItem actions={<RightActions/>} image={data?.right_image} style={{flexGrow: 1}}/>}
            </div>
            <AppMobileMenu show={showMenu} onHide={() => setShowMenu(false)}/>
        </>
    )
}