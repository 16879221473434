import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";
import Client1 from "../assets/pngs/max.png";
import Client2 from "../assets/pngs/image2.png";
import Client3 from "../assets/pngs/image3.png";
import Client4 from "../assets/pngs/image4.png";
import Client5 from "../assets/pngs/image5.png";
import Client6 from "../assets/pngs/maersk.png";
import Client7 from "../assets/pngs/image7.png";

const defaultData = [
    {image: Client1},
    {image: Client2},
    {image: Client3},
    {image: Client4},
    {image: Client5},
    {image: Client6},
    {image: Client7},
];

export async function getGSMPartners() {
    const response = await httpGetRequest('/partners?populate[image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}, id} = x;
        const {name,image} = attributes;
        return {name, image: fileUrl(image?.data?.attributes?.url)};
    })
}