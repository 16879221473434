export const SCREEN_WIDTH = {
    COMPACT: 1,
    MEDIUM: 2,
    EXPANDED: 3
}

export function getScreenType() {
    const width = window.innerWidth;
    if (width < 600) {
        // console.log('COMPACT')
        return SCREEN_WIDTH.COMPACT;
    } else if (width > 60 && width < 840) {
        // console.log('MEDIUM')
        return SCREEN_WIDTH.MEDIUM;
    } else if (width > 840) {
        // console.log('EXPANDED')
        return SCREEN_WIDTH.EXPANDED;
    } else {
        return SCREEN_WIDTH.EXPANDED
    }
}