import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultData = {
    logo: 'https://ipfs.io/ipfs/bafybeiahry5myx2myh4adgkpifaty4a7rd2nugy6jtyehuxrlqxerysavq/LOGO%20GSM%20TANZANIA-01%20(1).png',
    detail: 'GSM\'s manufacturing cluster is committed to providing top-notch building materials, furniture, and beverages. With advanced technology, skilled professionals, and affordable pricing, we have become a trusted name in the industry. Whether you are in need of galvanized wires, roofing sheets, nails, or refreshing beverages, GSM has got you covered. Our dedication to quality and customer satisfaction sets us apart from the competition, making us the go-to choice for all your manufacturing needs.',
    malls: [
        {
            name: 'GSM Roofing Sheets',
            image: 'https://ipfs.io/ipfs/bafybeic6sxdru46s6dadockl2qx2zpf223uzfrtijbtmohaj4qi5yj7nii/COLOUR_ROOFING%20SHEET.png'
        },
        {
            name: 'Galvanised Wire',
            image: 'https://ipfs.io/ipfs/bafybeiaelzql3sri56dzmkutv2yo6y26zm7y6iks64ymaubdmhn3ko76ki/GALVANIZ_WIRE.png'
        },
        {
            name: 'GSM Steel Nails',
            image: 'https://ipfs.io/ipfs/bafybeidv3jexjhgzuk3u3w266wpf5jku3xe2szo4rjrlgqxkocfnn5qn2m/Steel_nail.png'
        },
        {
            name: 'GSM Wire Mesh',
            image: 'https://ipfs.io/ipfs/bafybeif5w2zxjv6dqcvqivs52rgmyo6zjheyxdosgrvdqm4ianjw6f6bpu/wire_mesh.png'
        },
        {
            name: 'GSM Wire',
            image: 'https://cf-ipgfs.com/ipfs/bafybeiacqo7uzpwvd5r32s3hflsjbw7rojhokorkeklpsxlj6v2bha67fq/WIRE.png'
        },
        {
            name: 'GSM Zinc Roof',
            image: 'https://ipfs.io/ipfs/bafybeif4ovpiiqlw52lbag4hmptvrge5kvtctl65ndsgtsbpiulwzqh6ey/ZINC_ROOF.png'
        }
    ]
};

export async function getGSMBeverage() {
    const response = await httpGetRequest('/beverage-page?populate[logo][fields]=url&populate[services][populate][image][fields]=url');
    const {data = {}} = response ?? {};
    const x = !data ? defaultData : data;

    const {attributes = {}} = x ?? {};
    const {logo, services, details} = attributes;
    return {
        logo: fileUrl(logo?.data?.attributes?.url),
        detail:details,
        malls: itOrEmptyList(services).map(y => {
            return {
                name: y?.name,
                image: fileUrl(y?.image?.data?.attributes?.url)
            }
        })
    };
}