import {AppHeader} from "../../core/components/AppHeader";
import Footer from "../../core/components/Footer";
import NewsUpdates from "../../core/components/NewsUpdates";
import {AboutMenu} from "../components/AboutMenu";
import {useEffect, useRef, useState} from "react";
import {getGSMOurStory} from "../services/about_our_story";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {DisplayMedium} from "../../core/components/DisplayMedium";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {BodyLarge} from "../../core/components/BodyLarge";
import {gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import Container from "../../core/components/Container";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {BodyMedium} from "../../core/components/BodyMedium";
import {useScrollUp} from "../../core/hooks/scrollUp";
import {GSMVideoPlayer} from "../../core/components/VideoPlayer";


function ReadMoreContent({text}) {
    const [readyMore, setReadMore] = useState(false);
    // useEffect(()=>{
    //     console.log(`${text}`.length>200)
    //     setReadMore(()=>`${text}`.length>200)
    // },[text]);
    return (
        <>
            {
                !readyMore && <>
                    <BodyLarge>{`${text}`.substring(0, 200)}...</BodyLarge>
                    <WhiteSpace height={16}/>
                    <div onClick={() => {
                        setReadMore(true);
                    }} style={{cursor: 'pointer', height: 48}}>
                        <BodyMedium fontWeight={600} color={gsmRedColor}>
                            READ MORE
                        </BodyMedium>
                    </div>
                </>
            }
            {
                readyMore && <>
                    <BodyLarge>{text}</BodyLarge>
                    <WhiteSpace height={16}/>
                    <div onClick={() => {
                        setReadMore(false);
                    }} style={{cursor: 'pointer', height: 48}}>
                        <BodyMedium fontWeight={600} color={gsmRedColor}>
                            SHOW LESS
                        </BodyMedium>
                    </div>
                </>
            }
        </>
    )
}

function Founder({detail, image}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <div style={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                justifyContent: isSmallScreen ? 'center' : 'flex-end'
            }}>
                <div style={{height: 350, width: '100%', maxWidth: 300}}>
                    <BackgroundImage
                        style={{width: 200, height: 350}}
                        source={image}
                    />
                </div>
            </div>
            {
                !isSmallScreen && <>
                    <div style={{
                        width: 75,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}>
                        <div style={{
                            position: 'relative',
                            height: '100%',
                            minHeight: 300,
                            width: 3,
                            borderRadius: 5,
                            backgroundColor: gsmRedColor
                        }}/>
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            backgroundColor: gsmRedColor,
                            position: 'absolute',
                            top: 0
                        }}/>
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            backgroundColor: gsmRedColor,
                            position: 'absolute',
                            bottom: 0
                        }}/>
                    </div>
                </>
            }
            <div style={{
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: isSmallScreen ? '100%' : 400
                }}>
                    <WhiteSpace height={16}/>
                    <DisplayMedium
                        textAlign={isSmallScreen ? 'center' : 'justify'}
                        fontWeight={900}>FOUNDER</DisplayMedium>
                    <HeadlineSmall
                        textAlign={isSmallScreen ? 'center' : 'justify'}>WHERE IT ALL BEGAN</HeadlineSmall>
                    <WhiteSpace height={24}/>
                    <ReadMoreContent text={detail}/>
                </div>
            </div>
        </div>
    );
}

function President({name, video, detail}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    const videoElRef = useRef();
    // useEffect(() => {
    //     console.log(videoElRef?.current?.offsetWidth)
    // }, [])
    return (
        <div style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center"
        }}>
            <DisplayMedium fontWeight={900}>PRESIDENT</DisplayMedium>
            <HeadlineSmall>{name}</HeadlineSmall>
            <WhiteSpace height={16}/>
            <div style={{height: isSmallScreen ? 250 : 400, width: '100%'}}>
                <BackgroundImage
                    source={'https://cms.gsmgroup.africa/uploads/GSM_20_Picture_a5d77ee685.png'}/>
            </div>
            {/*<GSMVideoPlayer cRef={videoElRef} src={video}/>*/}
            <WhiteSpace height={16}/>
            <div style={{width: '100%', maxWidth: isSmallScreen ? '100%' : videoElRef?.current?.offsetWidth}}>
                <ReadMoreContent text={detail}/>
            </div>
        </div>
    )
}

function Timeline({items}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <DisplayMedium fontWeight={900}>GSM GROUP</DisplayMedium>
            <WhiteSpace height={8}/>
            <HeadlineSmall>TIMELINE</HeadlineSmall>
            <WhiteSpace height={24}/>
            <div style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                overflowX: "scroll",
                overflowY: "hidden",
            }}>
                {
                    itOrEmptyList(items).map((item, index) => {
                        return (
                            <div key={index + 'timelinesfyytjfhg'}
                                 style={{padding: 16, display: "flex", flexDirection: "column"}}>
                                <div style={{
                                    position: 'relative',
                                    width: 300,
                                    height: 62,
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <div style={{
                                        position: "absolute",
                                        top: 31,
                                        left: 0,
                                        right: 0,
                                        height: 5,
                                        backgroundColor: gsmRedColor,
                                        borderRadius: 10
                                    }}/>
                                    <div style={{
                                        position: 'absolute',
                                        padding: 12,
                                        backgroundColor: gsmRedColor,
                                        borderRadius: 10,
                                    }}>
                                        <HeadlineSmall color={gsmWhiteColor}>{item?.year}</HeadlineSmall>
                                    </div>
                                </div>
                                <BodyLarge>{item?.detail}</BodyLarge>
                                <div style={{flexGrow: 1}}/>
                                <div style={{height: 150, display: 'flex', justifyContent: 'center'}}>
                                    <img alt={''} style={{width: 'auto', maxWidth: '100%', height: 150}}
                                         src={item?.image}/>
                                </div>
                                <div style={{
                                    backgroundColor: gsmRedColor,
                                    height: 3,
                                    borderRadius: 3,
                                    width: '50%',
                                    marginTop: 10,
                                    marginBottom: 5
                                }}/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export function AboutOurStory() {
    const [ourStory, setOurStory] = useState({});

    useEffect(() => {
        getGSMOurStory().then(setOurStory).catch(() => {
        });
    }, []);
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <AboutMenu activeLink={'/about'}/>
            <WhiteSpace height={38}/>
            <Container>
                <Founder detail={ourStory?.founder_detail} image={ourStory?.founder_image}/>
                <WhiteSpace height={48}/>
                {/*<President*/}
                {/*    detail={ourStory?.president_detail}*/}
                {/*    name={ourStory?.president_name}*/}
                {/*    video={ourStory?.president_video}*/}
                {/*/>*/}
                <GSMVideoPlayer src={ourStory?.president_video}/>
                <WhiteSpace height={88}/>
            </Container>
            <Timeline items={ourStory?.timelines}/>
            <NewsUpdates/>
            <Footer/>
        </div>
    )
}
