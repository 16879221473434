import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultTimeline = [
    {
        year: '1995',
        detail: 'Mr. Mohamed Senior establishes our first business in Tanga, merchandising small goods and then expanding into cashew nut farming. ',
        image: 'https://ipfs.io/ipfs/bafybeiffkqldewjxgxnfhdhte4rmvqtrye3adgl7ffz7kh5p23eavcqgzq/shutterstock_2196069411.png'
    },
    {
        year: '1996',
        detail: 'Mohamed Senior retires, and the business relocated to Dar es Salaam under his sons’ guidance. The Home Shopping Centre has launched.',
        image: 'https://ipfs.io/ipfs/bafybeibq3lkzbxouymvfezloaflm5bvicvfb3yqkkoglmvkn4baj67bepi/shutterstock_778252423%20(1).png'
    },
    {
        year: '2012',
        detail: 'Became the exclusive distributor of Tiger ',
        image: 'https://ipfs.io/ipfs/bafybeibrzmsi6744puvhtm7mxk7y7ktetvrhxehtqgait5bnwj3gwz4rwy/trading101-01.png'
    },
    {
        year: '2013',
        detail: 'GSM Mall introduces Babyshop, Max, Splash and Shoexpress.',
        image: 'https://ipfs.io/ipfs/bafybeiamn6uxg77thgpifahpglbxxt3wyqy3bistfk2tfrrlbic6xutmqq/Screenshot%202023-06-23%20at%2010.53.04.png'
    },
    {
        year: '2014',
        detail: 'Ghalib Said Mohamed incorporates the business and establishes a headquarters in Dar es Salaam. GALCO and trading divisions are set up.',
        image: 'https://ipfs.io/ipfs/bafybeigyvv7lk3jerwxkobj4xkw642bbxw5oorqyqdm3xeow6ulrtikybu/Group%201877%20(1).png'
    },
    // {
    //     year: '2015',
    //     detail: 'GSM Steel is incorporated and joins the manufacturing division to produce roofing sheets, galvanized wire and steel pipes.',
    //ipfs.io/ipfs/bafybeie3en2quve6bkze3vc5p3ixfz2ty3swp6ynjit56c23bwwyz7by3i/Screenshot%202023-06-23%20at%2010.55.43.png'
    // },
    {
        year: '2016',
        detail: 'GSM Home is launched as part of GSM Group and quickly becomes a leader in the home and office furniture industry. \n' +
            '\n' +
            'GSM Spares is established as a provider of spare parts, tires and lubricants.\n',
        image: 'https://ipfs.io/ipfs/bafybeihfaceewm3op7vcfeqhyzxco6gzeebv2mzed3h4vercympd4ry3ou/WhatsApp%20Image%202023-07-23%20at%2014.04.19.jpeg'
    },
    {
        year: '2018',
        detail: 'GSM Coconut Cream joins the food basket in the trading division.',
        image: 'https://ipfs.io/ipfs/bafybeieufpxz5ekc4mx3rkrgdwc3ozmflc2fo4dnpxlg3zeveg6gtsqaxi/Coconut_600-X-460-copy.png'
    },
    {
        year: '2019',
        detail: 'Officially sponsored Young Africans Sports Club (Yanga).',
        image: 'https://ipfs.io/ipfs/bafybeifpwpmnxsc6377csme4pdwyiit3tqa4urlj6bvquno3kyrk35u2ea/Yanga%20n.png'
    },
    {
        year: '2023',
        detail: 'Partnered with Haier to become their exclusive distributor for home appliances and consumer electronics.',
        image: 'https://ipfs.io/ipfs/bafybeihvkr5ubnxyhv5cxw5tpi3r46firv36mvr3ddttnn5ymry3wsyesy/Haier%20Prodcuct%20Group.png'
    }
];

export async function getGSMOurStory() {
    const response = await httpGetRequest('/about-our-story?populate[founder_image][fields]=url&populate[timelines][populate][image][fields]=url&populate[president_video][fields]=url');
    const {data = {}} = response ?? {};
    const {attributes = {}} = data;
    const {founder_name,founder_detail,founder_image,president_name,president_detail,timelines} = attributes;
    return {
        founder_name: founder_name??'Founder name',
        founder_detail: founder_detail??'Late Mr. Mohamed Senior established the first business in 1955, as a merchandiser of small goods in Tanga. Once his sons took over the business in 1996 and moved to Dar es Salaam, the family-run business expanded into trading, distribution, and logistics. We have achieved this by successfully identifying and responding to client requirements while adopting new trends in various sectors and industries. \n' +
            'We now manage major businesses within Tanzania and in Central, Eastern and Southern Africa. We strive to strategically manage costs as we optimize value across businesses.\n' +
            'In line with our growth, our manpower has increased substantially to more than 3,000 diverse and highly qualified staff. We understand that people are our key asset, and we focus on recruiting and retaining the best. \n' +
            'Our group is divided into five clusters: Logistics, Manufacturing, Trading, Retail, and Real Estate. \n',
        founder_image: fileUrl(founder_image?.data?.attributes?.url)??'https://ipfs.io/ipfs/bafybeia2ywyx45wqyqo5vantr7rtzlmnrgrl4rc3ikrilpd4p75wvagm4e/Rectangle%201276.png',
        president_name: president_name??'MR. GHALIB SAID MOHAMMED',
        president_detail: president_detail??'Born into a successful family business, Mr. Ghalib Said Mohamed has taken GSM Group to new heights while serving the communities around him. He learnt from his father’s thriving cashew farming business and expanded it beyond Tanzania’s borders. His current businesses span several countries such as China, Dubai, Tanzania, Zambia, Burundi, DRC, and South Africa.\n' +
            '\n' +
            'After incorporating the family business in 2014, Ghalib has created a diverse conglomerate that provides trading, transportation, logistics, manufacturing, real estate and retail services. By representing international brands from China, Dubai and Spain, he is able to offer unique and quality products to the Tanzanian consumer. \n' +
            '\n' +
            'Ghalib’s vision for GSM goes beyond East and Central Africa. He envisions a pan-African company that creates various jobs for Africans all over the continent and provides exceptional services in every country',
        president_video: 'https://ipfs.io/ipfs/bafybeicxw46bh6qlu7wa2j6u5pcg2s7rcu67wfd2yp3xwhshbhltqu4ava/videoplayback.mp4',
        timelines: itOrEmptyList(timelines).length===0?defaultTimeline:itOrEmptyList(timelines).map(x=>{
            return {
                year: x?.year,
                detail: x?.detail,
                image: fileUrl(x?.image?.data?.attributes?.url)
            };
        })
    }
}
