export const DEPARTMENT_SLAGS = {
    transport: 'transport',
    building: 'building',
    fashion: 'fashion',
    food: 'food',
    furniture: 'furniture',
    realEstate: 'realEstate',
    trucksSpare: 'trucksSpare',
    electronics: 'electronics',
    crs: 'crs',
}