export function LabelMedium({children, color, fontWeight = 300}) {
    return (
        <span style={{
            lineHeight: '1.05em',
            fontFamily: 'Open Sans',
            fontSize: '0.75em',
            display: 'block',
            color,
            fontWeight,
        }}>{children}</span>
    );
}
