import axios from "axios";

const getToken = 'd553da19a6f1412778fe58e7fbdf056f6d4376d3e3faa6710c7cc0a7a510746aceb5e686f9f9aca793e39f616733a2e93f1bf2fa9b4c19fe39ad7df603f84f72e8f98994ce1ccf36f0ba454fc24cd39934bae80eabede601ec0de9389ed1299a73e6be5433ccbb4b2583550099522518156f3b117774d1fd4069aeff7d8ce7b9';
const subscriptionPutToken = 'e7257160a56545b385e26456cd318bdc45e434399b4d6aab56a89201cd8cd510885a6a19f94bd122dd32ecacfe2e15b9752c1f064c99899359ee056f4760f559c9420be4193252e392b2b72becff1e79a7a1f6ac240b520d6b171976bc393b2db8da58af928ad1fe9bd060819566729b437d514765bfbc1a47813c25398954c5'
const base = 'https://cms.gsmgroup.africa/api/';

export const httpGetRequest = async (path) => {
    try {
        const _path = `${path}`.trim().startsWith('/') ? `${path}`.replace('/', '') : path;
        const response = await axios.get(`${base}${_path}`, {
            headers: {
                'Authorization': `Bearer ${getToken}`
            }
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function emailSubscriptionHttpRequest(email) {
    // try {
        // const _path = `${path}`.trim().startsWith('/')?`${path}`.replace('/',''):path;
        const response = await axios.post(`${base}subcriptions`, {
            data: {
                email
            }
        }, {
            headers: {
                'Authorization': `Bearer ${subscriptionPutToken}`
            }
        });
        return response.data;
    // } catch (e) {
    //     console.log(e);
    //     return null;
    // }
}