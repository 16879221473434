import {AppHeader} from "../../core/components/AppHeader";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import React, {useEffect, useMemo, useState} from "react";
import Container from "../../core/components/Container";
import {gsmWhiteColor} from "../../core/services/colors.mjs";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {BodyLarge} from "../../core/components/BodyLarge";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {getGSMCompanies} from "../services/companies";
import {ReactComponent as ArrowRightIcon} from "../assets/arrow_right.svg";
import {Link} from "react-router-dom";
import {useScrollUp} from "../../core/hooks/scrollUp";


function Body({data}) {
    const scrollTo = useMemo(() => {
        return () => {

        }
    }, []);
    return (
        <Container>
            <WhiteSpace height={24}/>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {
                    itOrEmptyList(data).map((item, index) => {
                        return (
                            <Link
                                onClick={scrollTo}
                                to={'/companies/' + `${item?.id}`.split('/').pop()}
                                key={`${index}_head_cluster_title`}
                                style={{flexGrow: 1, width: '100%', padding: 8, cursor: 'pointer'}}>
                                <BodyLarge textAlign={'center'}>{item?.cluster_name}</BodyLarge>
                            </Link>
                        )
                    })
                }
            </div>
            <WhiteSpace height={24}/>
            <div>
                {
                    itOrEmptyList(data).map((item, index) => {
                        return (
                            <Link
                                to={'/companies/' + `${item?.id}`.split('/').pop()}
                                id={`${index}_head_cluster_body`}
                                key={`${index}_head_cluster_body`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div style={{width: '100%', height: 350, position: 'relative'}}>
                                    <BackgroundImage title={item?.cluster_name} source={item?.cluster_image}/>
                                    <div style={{
                                        // width: '30%',
                                        padding: 5,
                                        position: 'absolute',
                                        right: 11,
                                        bottom: 0,
                                        top: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                        zIndex: 99999
                                    }}>
                                        <div style={{
                                            // flexGrow: 1,
                                            // width: '100%',
                                            // margin: 5,
                                            backgroundColor: gsmWhiteColor,
                                            borderRadius: 5,
                                            // boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.16)'
                                        }}>
                                            <div style={{padding: 16}}>
                                                <img style={{
                                                    width: 'auto',
                                                    maxWidth: 100,
                                                    height: 'auto',
                                                    borderRadius: 20
                                                }}
                                                     src={item?.cluster_logo}
                                                     alt={item?.cluster_name ?? 'Logo'}/>
                                            </div>
                                        </div>
                                        <WhiteSpace height={24}/>
                                        <ArrowRightIcon/>
                                    </div>
                                </div>
                                <WhiteSpace height={16}/>
                            </Link>
                        );
                    })
                }
            </div>
        </Container>
    );
}

export function OurCompaniesPage() {
    const [companies, setCompanies] = useState([]);
    useEffect(() => {
        getGSMCompanies().then(setCompanies).catch(() => {
        })
    }, []);
    useScrollUp();
    return (
        <>
            <AppHeader/>
            <Body data={companies}/>
            <NewsUpdates/>
            <Footer/>
        </>
    )
}