import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl} from "../../core/services/utils.mjs";

export async function getAboutUsDetails() {
    const response = await httpGetRequest('/home-about?populate[video][fields]=url');
    const {attributes} = response?.data;
    const {mission,vision,short_description,video} = attributes??{};
    return {
        video: fileUrl(video?.data?.attributes?.url)??'https://ipfs.io/ipfs/bafybeicxw46bh6qlu7wa2j6u5pcg2s7rcu67wfd2yp3xwhshbhltqu4ava/videoplayback.mp4',
        short_description: short_description??'GSM Group is an innovation-driven conglomerate that offers a wide range of services\n' +
            '                        across the\n' +
            '                        African continent.',
        mission: mission??'GSM Group is an innovation-driven conglomerate that offers a wide range of services\n' +
            '                        across the\n' +
            '                        African continent.',
        vision: vision??'We are committed to providing excellent services and quality products while bringing together\n' +
            '                        companies, talented individuals, and first-class resources.'
    }
}
