import React from "react";
import CustomerServiceImage from "../assets/png/customer-service.png";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {gsmRedColor} from "../../core/services/colors.mjs";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";

export function CustomerService() {
    return (
        <div style={{position: 'relative'}}>
            <img src={CustomerServiceImage} alt={'Customer Service'} width={'100%'} style={{minHeight: 200}} height={'auto'}/>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
                <div style={{
                    paddingLeft: 24,
                }}>
                    <HeadlineSmall>GET IN TOUCH</HeadlineSmall>
                    <WhiteSpace height={8}/>
                    <HeadlineLarge fontWeight={900} color={gsmRedColor}>CONTACT US</HeadlineLarge>
                </div>
            </div>
        </div>
    )
}