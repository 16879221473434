import {itOrEmptyList} from "../services/utils.mjs";
import {useEffect, useState} from "react";

/***
 *
 * @param items {Array<JSX.Element>}
 * @returns {JSX.Element}
 * @constructor
 */
export function ExpandableFold({items}) {
    const [rows, setRows] = useState([[]]);
    useEffect(() => {
        const m = itOrEmptyList(items).reduce((a, b) => {
            const last = [...a].pop()
            if (Array.isArray(last)) {
                if (last.length < 2) {
                    last.push(b);
                    return [...a];
                } else {
                    return [...a, [b]];
                }
            } else {
                return [...a, [b]];
            }
        }, [[]]);
        setRows(m);
    }, [items]);
    return (
        <div style={{display: "flex", flexDirection: "column", height: '100%'}}>
            {rows.map((x, i) => {
                return (
                    <div
                        style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', flexGrow: 1}}
                        key={'hbi_fold' + i}
                    >
                        {x.map((y, i) => {
                            return (
                                <div key={'hbi_i_fold' + i}
                                     style={{flexGrow: 1, margin: 5, cursor: "pointer"}}>
                                    {y}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
    // const [data, setData] = useState([]);
    //
    // useEffect(() => {
    //     const _items = itOrEmptyList(items);
    //     if (_items.length > 0) {
    //         if (_items.length >= 2) {
    //             setData(_items.slice(0, 2));
    //         } else {
    //             setData(_items);
    //         }
    //     }
    // }, [items]);
    //
    // return (
    //     <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: direction, flexWrap: "nowrap",}}>
    //         {
    //             data.map((item, index) => {
    //                 return (
    //                     <div key={index + 'auto_expand'} style={{flexGrow: 1, margin: 5}}>
    //                         {item}
    //                     </div>
    //                 );
    //             })
    //         }
    //     </div>
    // );
}
