import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Container from "../../core/components/Container";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import React from "react";
import {useScrollUp} from "../../core/hooks/scrollUp";


export function RetailFurniturePage() {
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <WhiteSpace height={48}/>
            <Container>

            </Container>
            <NewsUpdates/>
            <Footer/>
        </div>
    );
}