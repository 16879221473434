import Container from "../../core/components/Container";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {gsmRedColor} from "../../core/services/colors.mjs";
import React from "react";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";


export function Location() {
    return (
        <div>
            <Container>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 100,
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <HeadlineLarge fontWeight={600}>Get</HeadlineLarge>
                        <WhiteSpace width={16}/>
                        <HeadlineLarge fontWeight={600} color={gsmRedColor}>Direction</HeadlineLarge>
                    </div>
                </div>
            </Container>
            <WhiteSpace height={70}/>
            <div>
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.606940174791!2d39.28667317535711!3d-6.817567893180127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4ba772f8d3b3%3A0xe41f06ec8a9d63e6!2sGSM%20Group!5e0!3m2!1sen!2stz!4v1687811408633!5m2!1sen!2stz"
                    width="100%" height="700" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}
