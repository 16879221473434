import React, {useEffect, useState} from "react";
import {AppHeader} from "../../core/components/AppHeader";
import Footer from "../../core/components/Footer";
import NewsUpdates from "../../core/components/NewsUpdates";
import {AboutMenu} from "../components/AboutMenu";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import {gsmBgGreyColor, gsmBlackColor, gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import Container from "../../core/components/Container";
import {BodyLarge} from "../../core/components/BodyLarge";
import {getGSMAboutGovernance} from "../services/about_governance";
import {itOrEmptyList} from "../../core/services/utils.mjs";
import {LabelMedium} from "../../core/components/LabelMedium";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {BodyMedium} from "../../core/components/BodyMedium";
import {Link} from "react-router-dom";
import {ReactComponent as LinkedIcon} from "../assets/svgs/linkedin.svg";
import {useScrollUp} from "../../core/hooks/scrollUp";


function ExploreMore({link}) {
    return (
        <Link to={'/companies/' + link?.split('/')?.pop()}>
            <div style={{
                width: '100%',
                backgroundColor: gsmRedColor,
                borderRadius: 4,
                // padding: 8,
                height: 48,
                cursor: 'pointer',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <BodyMedium fontWeight={600} color={gsmWhiteColor}>EXPLORE MORE</BodyMedium>
            </div>
        </Link>
    );
}

function Header() {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <WhiteSpace height={48}/>
            <HeadlineSmall color={gsmBlackColor}>OUR TEAM</HeadlineSmall>
            <HeadlineLarge fontWeight={900} color={gsmRedColor}>GSM GROUP</HeadlineLarge>
            <HeadlineLarge fontWeight={900} color={gsmRedColor}>GOVERNANCE</HeadlineLarge>
            <WhiteSpace height={48}/>
        </div>
    );
}

function President({data}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT
    return (
        <Container>
            <div style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center'
            }}>
                <div style={{width: 250, paddingRight: 16}}>
                    <img src={data?.image} alt={'President'} style={{width: 250, height: 'auto', borderRadius: 10}}/>
                </div>
                <WhiteSpace width={24}/>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: "flex-start"}}>
                    <BodyLarge>{data?.detail}</BodyLarge>
                    {/*<WhiteSpace height={16}/>*/}
                    {/*<img style={{width: 100, height: 'auto'}} src={data?.sign} alt={'President sign'}/>*/}
                    <WhiteSpace height={16}/>
                    <HeadlineLarge fontWeight={900}>{data?.name}</HeadlineLarge>
                    <HeadlineSmall color={gsmRedColor}>PRESIDENT - GSM GROUP</HeadlineSmall>
                    <WhiteSpace height={16}/>
                </div>
            </div>
        </Container>
    );
}
function GroupCEO({data}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT
    return (
        <Container>
            <div style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center'
            }}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: "flex-end"}}>
                    <BodyLarge>{data?.detail}</BodyLarge>
                    <WhiteSpace height={16}/>
                    <HeadlineLarge fontWeight={900}>{data?.name}</HeadlineLarge>
                    <HeadlineSmall color={gsmRedColor}>GROUP CEO</HeadlineSmall>
                    <WhiteSpace height={16}/>
                </div>
                <WhiteSpace width={24}/>
                <div style={{width: 250, paddingLeft: 16}}>
                    <img src={data?.image} alt={'Group ceo'} style={{width: 250, height: 'auto', borderRadius: 10}}/>
                </div>
            </div>
        </Container>
    );
}

function MemberCard({index, image, name, position, office, linkedIn}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <div style={{
            flexGrow: 1,
            width: isSmallScreen ? '100%' : '98%',
            margin: isSmallScreen ? 0 : '2%',
            backgroundColor: gsmWhiteColor,
            borderRadius: 10,
            boxShadow: isSmallScreen ? 'none' : '0px 3px 3px 0px rgba(0, 0, 0, 0.16)'
        }}>
            <div style={{
                ...{padding: 16},
                ...isSmallScreen ? {
                    display: 'flex',
                    flexDirection: 'column',
                    // maxWidth: 250,
                    justifyContent: 'center',
                    alignItems: 'center'
                } : {}
            }}>
                <img style={{width: '250px', height: 'auto', borderRadius: 20}}
                     src={image}
                     alt={''}/>
                <div>
                    <BodyLarge fontWeight={500}>{name}</BodyLarge>
                    <WhiteSpace height={5}/>
                    <LabelMedium fontWeight={600} color={gsmRedColor}>{position}</LabelMedium>
                    {linkedIn && linkedIn !== '' && <>
                        <Link target={'_blank'} style={{
                            display: "inline-block",
                            marginTop: 8,
                            width: 24,
                            height: 24
                        }} to={linkedIn}>
                            <LinkedIcon width={24} height={24}/>
                        </Link>
                    </>}
                    {/*<WhiteSpace height={5}/>*/}
                    {/*<LabelMedium fontWeight={400} color={gsmRedColor}>{office}</LabelMedium>*/}
                </div>
            </div>
        </div>
    );
}

function Members({members}) {
    const [data, setData] = useState([]);

    useEffect(() => {
        let items = itOrEmptyList(members);
        let chunkSize = getScreenType() === SCREEN_WIDTH.COMPACT ? 1 : getScreenType() === SCREEN_WIDTH.MEDIUM ? 2 : 3;
        let chunks = [];

        while (items.length > 0) {
            chunks.push(items.splice(0, chunkSize));
        }
        setData(chunks);
    }, [members]);

    return (
        <div style={{backgroundColor: gsmBgGreyColor}}>
            <WhiteSpace height={48}/>
            <Container>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    {
                        itOrEmptyList(data).map((x, index) => {
                            return (
                                <div key={`${index}_members`} style={{display: 'flex', flexDirection: 'row'}}>
                                    {
                                        itOrEmptyList(x).map((y, index) => {
                                            return <div key={`${index}_inner_member`}>
                                                <MemberCard
                                                    office={y?.office}
                                                    position={y?.position}
                                                    name={y?.name}
                                                    image={y?.image}
                                                    linkedIn={y?.linked_in}
                                                    index={index}
                                                />
                                            </div>
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </Container>
            <WhiteSpace height={48}/>
        </div>
    );
}

function ClusterHeads({heads}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <HeadlineSmall color={gsmBlackColor}>CLUSTER HEADS</HeadlineSmall>
                <HeadlineLarge color={gsmRedColor} fontWeight={900}>CHIEF EXECUTIVE</HeadlineLarge>
                <HeadlineLarge color={gsmRedColor} fontWeight={900}>OFFICERS</HeadlineLarge>
            </div>
            <WhiteSpace height={24}/>
            <WhiteSpace height={24}/>
            <div>
                {
                    itOrEmptyList(heads).map((item, index) => {
                        return (
                            <div
                                id={`${index}_head_cluster_body`}
                                key={`${index}_head_cluster_body`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div style={{width: '100%', height: 350}}>
                                    <BackgroundImage title={item?.cluster_name} source={item?.cluster_image}/>
                                </div>
                                <WhiteSpace height={16}/>
                                <div style={{display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row'}}>
                                    <div style={{flexGrow: isSmallScreen ? 1 : 0.60, padding: 8}}>
                                        <BodyLarge>{item?.cluster_details}</BodyLarge>
                                        <div style={{flexGrow: 1}}/>
                                        {
                                            !isSmallScreen && <>
                                                <WhiteSpace height={16}/>
                                                <ExploreMore link={item?.cluster_id}/>
                                                <WhiteSpace height={16}/>
                                            </>
                                        }
                                    </div>
                                    <div style={{
                                        flexGrow: isSmallScreen ? 1 : 0.40,
                                        width: '100%',
                                        padding: isSmallScreen ? 0 : '0 24px',
                                        marginTop: isSmallScreen ? 0 : -150,
                                        zIndex: isSmallScreen ? 0 : 99999
                                    }}>
                                        <MemberCard
                                            index={index + 'cluster'}
                                            name={item?.name}
                                            image={item?.image}
                                            position={item?.position}
                                            office={`${item?.cluster_name} CLUSTER`.toUpperCase()}
                                        />
                                        {
                                            isSmallScreen && <>
                                                <WhiteSpace height={16}/>
                                                <ExploreMore link={item?.cluster_id}/>
                                                <WhiteSpace height={24}/>
                                            </>
                                        }
                                    </div>
                                </div>
                                <WhiteSpace height={16}/>
                            </div>
                        );
                    })
                }
            </div>
        </Container>
    );
}

export function AboutGovernance() {
    const [governance, setGovernance] = useState({});

    useEffect(() => {
        getGSMAboutGovernance().then(setGovernance).catch(_ => {
        });
    }, []);
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <AboutMenu activeLink={'/about_governance'}/>
            <Header/>
            <President data={governance?.president}/>
            <WhiteSpace height={48}/>
            <GroupCEO data={governance?.group_ceo}/>
            <WhiteSpace height={48}/>
            <Members members={governance?.teams}/>
            <WhiteSpace height={48}/>
            <ClusterHeads heads={governance?.heads}/>
            <NewsUpdates/>
            <Footer/>
        </div>
    )
}
