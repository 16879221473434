export function BodyLarge({children, color, fontWeight = 400, textAlign='justify'}) {
    return (
        <span style={{
            lineHeight: '1.5em',
            fontFamily: 'Open Sans',
            fontSize: '1.1em',
            display: 'block',
            textAlign,
            color,
            fontWeight,
        }}>{children}</span>
    );
}
