import React from "react";
import Container from "../../core/components/Container";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {gsmRedColor} from "../../core/services/colors.mjs";
import Paragraph from "../../core/components/Paragraph";
import {TitleMedium} from "../../core/components/TitleMedium";
import {BodyMedium} from "../../core/components/BodyMedium";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";

export function Mail() {

    const formInputs = [
        {
            'title': 'Your Name',
            placeholder: 'Enter your Full Names',
            isRequired: true
        },
        {
            'title': 'Your Email',
            placeholder: 'handle@yourdomain.com',
            isRequired: true
        },
        {
            'title': 'Your Phone Number',
            placeholder: '+2557XXXXXXXX',
            isRequired: true
        },
        {
            'title': 'Subject',
            placeholder: 'Enter your Subject',
            isRequired: false
        },
    ]
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <Container>
            <div style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'space-between',
                // paddingLeft: 16,
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <HeadlineLarge fontWeight={600}>Instant </HeadlineLarge>
                    <WhiteSpace width={16}/>
                    <HeadlineLarge fontWeight={600} color={gsmRedColor}>Mail</HeadlineLarge>
                </div>
                <WhiteSpace width={24} height={24}/>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <Paragraph color={gsmRedColor}>Require fields are marked *</Paragraph>
                    <WhiteSpace height={20}/>
                    <div style={{width: '100%', maxWidth: 600}}>
                        {
                            formInputs.map((element, index) => {
                                return (
                                    <div key={index + 'form_input'}>
                                        <TitleMedium>{element?.title} {element?.isRequired && (
                                            <span style={{color: gsmRedColor}}>*</span>)}</TitleMedium>
                                        <WhiteSpace height={10}/>
                                        <input
                                            style={{
                                                backgroundColor: 'white',
                                                // paddingLeft: 15,
                                                // paddingRight: 15,
                                                width: '100%',
                                                fontSize: 16,
                                                height: 58,
                                                borderWidth: .5,
                                                borderRadius: 8
                                            }}
                                            type="text"
                                            placeholder={element?.placeholder}
                                        />
                                        <WhiteSpace height={20}/>
                                    </div>
                                )
                            })
                        }

                        <TitleMedium>Your Message <span style={{color: gsmRedColor}}>*</span> </TitleMedium>
                        <WhiteSpace height={10}/>
                        <textarea
                            rows={6}
                            value={""}
                            onChange={(e) => {
                            }}
                            style={{
                                width: '100%',
                                borderWidth: .5,
                            }}
                        />
                        <WhiteSpace height={10}/>
                        <button
                            style={{
                                border: 'none',
                                backgroundColor: gsmRedColor,
                                color: 'white',
                                padding: 15,
                                fontSize: 20,
                                height: 58,
                                cursor: "pointer",
                                width: 200
                            }}>
                            <BodyMedium>Submit Here</BodyMedium>
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}
