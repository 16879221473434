export function LabelLarge({children, color, fontWeight = 300}) {
    return (
        <span style={{
            lineHeight: '1.05em',
            fontFamily: 'Open Sans',
            fontSize: '0.875em',
            color,
            fontWeight,
        }}>{children}</span>
    );
}
