import React, {useEffect, useState} from 'react';
import {Center} from "../../core/components/Center";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {gsmRedColor} from "../../core/services/colors.mjs";
import {HeadlineLarge} from "../../core/components/HeadlineLarge";
import {getGSMPartners} from "../services/partners.mjs";

const ClientsCarousel = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        getGSMPartners().then(setPartners).catch(_ => null);
    }, []);

    return (
        <div style={{margin: 0}}>
            <div style={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                {partners.map((item, index) => (
                    <div style={{display: 'inline-block', margin: 16}} key={`${item?.name ?? index}_Carousel`}>
                        <img src={item?.image} height={120} alt={`Client ${index + 1}`}/>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default function HomeGroupClients() {
    return (
        <>
            <div style={{
                flexDirection: 'column',
            }}>
                <Center><HeadlineSmall fontWeight={500}>WE ARE SERVING</HeadlineSmall></Center>
                <WhiteSpace height={10}/>
                <Center><HeadlineLarge fontWeight={900} color={gsmRedColor}>GSM GROUP PARTNERS</HeadlineLarge></Center>
                <WhiteSpace height={70}/>
                <ClientsCarousel/>
            </div>
        </>
    )
}