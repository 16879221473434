import './index.css';
import {HomePage} from "./modules/home/pages/HomePage";
import {AboutOurStory} from "./modules/about/pages/AboutOurStory";
import {createHashRouter, RouterProvider,} from "react-router-dom";
import {AboutUs} from "./modules/about/pages/AboutUs";
import {AboutGovernance} from "./modules/about/pages/AboutGovernance";
import {AboutSponsorship} from "./modules/about/pages/AboutSponsorship";
import {ContactUs} from "./modules/contact/pages/ContactUs";
import {OurCompaniesPage} from "./modules/companies/pages/OurCompaniesPage";
import {LogisticPage} from "./modules/companies/pages/LogisticPage";
import {ManufacturingPage} from "./modules/companies/pages/ManufacturingPage";
import {TradingPage} from "./modules/companies/pages/TradingPage";
import {RetailPage} from "./modules/companies/pages/RetailPage";
import {PressAndMediaView} from "./modules/home/pages/PressAndMediaView";
import {useEffect} from "react";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {RetailFashionPage} from "./modules/companies/pages/RetailFashion";
import {RetailFurniturePage} from "./modules/companies/pages/RetailFurninture";
import {AboutCareerPage} from "./modules/about/pages/AboutCareerPage";
import {BeveragePage} from "./modules/companies/pages/BeveragePage";


const router = createHashRouter([
    {
        path: "/",
        element: <HomePage/>,
    },
    {
        path: "/updates",
        element: <PressAndMediaView/>,
    },
    {
        path: "about",
        element: <AboutOurStory/>,
    },
    {
        path: "about_us",
        element: <AboutUs/>,
    },
    {
        path: "about_governance",
        element: <AboutGovernance/>,
    },
    {
        path: "about_sponsorship",
        element: <AboutSponsorship/>,
    },
    {
        path: "about_career",
        element: <AboutCareerPage/>,
    },
    {
        path: "companies",
        element: <OurCompaniesPage/>,
    },
    {
        path: "companies/logistics",
        element: <LogisticPage/>,
    },
    {
        path: "companies/manufacturing",
        element: <ManufacturingPage/>,
    },
    {
        path: "companies/beverage",
        element: <BeveragePage/>,
    },
    {
        path: "companies/trading",
        element: <TradingPage/>,
    },
    {
        path: "companies/retail",
        element: <RetailPage/>,
    },
    {
        path: "companies/retail/fashion",
        element: <RetailFashionPage/>,
    },
    {
        path: "companies/retail/furniture",
        element: <RetailFurniturePage/>,
    },
    {
        path: "products",
        element: <OurCompaniesPage/>,
    },
    {
        path: "contacts",
        element: <ContactUs/>,
    },
    {
        path: '*',
        element: <HomePage/>
    }
]);

function App() {
    useEffect(() => {
        const firebaseConfig = {
            apiKey: "AIzaSyDX86UXtUgjkFnvHdrlRK84_NrO47GFU7U",
            authDomain: "gprojecttz.firebaseapp.com",
            projectId: "gprojecttz",
            storageBucket: "gprojecttz.appspot.com",
            messagingSenderId: "1028890276521",
            appId: "1:1028890276521:web:d740819ec996b96aaf6fb8",
            measurementId: "G-W1HBDWTRD9"
        };
        const app = initializeApp(firebaseConfig);
        getAnalytics(app);
    }, [])
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
