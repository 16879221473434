import Container from "./Container";
import {HeadlineLarge} from "./HeadlineLarge";
import {Center} from "./Center";
import React, {useRef, useState} from "react";
import {WhiteSpace} from "./WhiteSpace";
import {gsmRedColor} from "../services/colors.mjs";
import {LabelMedium} from "./LabelMedium";
import {BodyMedium} from "./BodyMedium";
import {getScreenType, SCREEN_WIDTH} from "../services/layout";
import {emailSubscriptionHttpRequest} from "../services/network.mjs";

function Input() {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    return (
        <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
            <div style={{width: isSmallScreen ? '100%' : '80%', display: 'flex', flexDirection: 'row'}}>
                <input
                    ref={inputRef}
                    onChange={event => {
                        setEmail(event?.target?.value);
                    }}
                    style={{
                        border: 'none',
                        backgroundColor: 'white',
                        paddingLeft: 15,
                        paddingRight: 15,
                        flex: 1,
                        maxWidth: 600,
                        fontSize: 16,
                        // height: 58,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                    }}
                    type="text"
                    placeholder={'Enter Your Email to Subscribe'}
                />
                <button
                    onClick={() => {
                        if (email) {
                            setLoading(true);
                            emailSubscriptionHttpRequest(email).then(value => {
                                setEmail('');
                                inputRef.current.value=''
                                alert('Successful submitted');
                            }).catch(reason => {
                                alert(reason);
                            }).finally(() => {
                                setLoading(false);
                            });
                        }
                    }}
                    style={{
                        border: 'none',
                        backgroundColor: gsmRedColor,
                        color: 'white',
                        padding: 15,
                        fontSize: 20,
                        // height: 58,
                        cursor: "pointer",
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8
                    }}>
                    <LabelMedium fontWeight={500}>SUBSCRIBE HERE</LabelMedium>
                </button>
            </div>
        </div>
    )
}

function NewsUpdatesContent() {
    return (
        <div style={{
            backgroundColor: '#F5F5F5',
            // paddingTop: '50px',
            // paddingBottom: '50px',
            padding: 16,
            borderRadius: 8,
            // flexDirection: 'column',
        }}>
            <BodyMedium>
                <Center>Be the first to hear GSM</Center>
            </BodyMedium>
            <WhiteSpace height={8}/>
            <HeadlineLarge>
                <Center>News and Updates</Center>
            </HeadlineLarge>
            <WhiteSpace height={50}/>
            <Center>
                <Input/>
            </Center>
            <WhiteSpace height={50}/>
        </div>
    )
}

export default function NewsUpdates({hasContainer = true}) {
    return (
        <>
            <WhiteSpace height={48}/>
            {
                hasContainer ? (
                    <Container>
                        <NewsUpdatesContent/>
                    </Container>
                ) : <NewsUpdatesContent/>
            }
            <WhiteSpace height={48}/>
        </>
    )
}
