import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultData = [
    {
        id: 1,
        image: 'https://ipfs.io/ipfs/bafybeid4t2fehxmbopevtkml4dkwvrgmmdjon2eq3wvdpovt6ugjex3mvq/WhatsApp%20Image%202023-05-17%20at%2020.09.34.png',
        title: 'GSM TO LAUNCH ‘HAIER’ BRAND IN\n' +
            'TANZANIA ON 18th MAY 2023',
        detail: '',
        tag: 'NewBusinessVentures',
        createdAt: '',
        readTimeInMin: '3',
        link: 'https://www.youtube.com/watch?v=1g8Wi-iqRzU'
    },
    {
        id: 2,
        image: 'https://ipfs.io/ipfs/bafybeig4zyf6od4kzbkcotj5z5zuqxh5tjmqmndvsar33usjqfkhyltkem/AW2I9087.png',
        title: 'GSM HOME LAUNCHES ITS 6TH STORE IN PUGU MALL, DAR ES SALAAM, TANZANIA',
        detail: '',
        tag: 'GSMHomeTZ',
        createdAt: '',
        readTimeInMin: '5',
        link: 'https://www.youtube.com/watch?v=dxBWZxc6JQ4'
    },
    {
        id: 3,
        image: 'https://ipfs.io/ipfs/bafybeiguektprc4e45fbpb6yffvxcicil7x4t2syuopruh6zksskwid6ha/images-32.png',
        title: 'YANGA BINGWA\n' +
            'CHAMPIONS NBCPL 22',
        detail: '',
        tag: 'GSMSponsorship',
        createdAt: '',
        readTimeInMin: '10',
        link: 'https://www.youtube.com/watch?v=ASeX_ntsEng'
    },
    {
        id: 4,
        image: 'https://ipfs.io/ipfs/bafybeid5pv6wg7ecvpcrqimmltkevv4bogo3tzrajshkbajpc4v6hh7umi/GSM-TANZANIA-1-01-2048x1381%20(1).png',
        title: 'BEST WHOLESALE PRICES \n' +
            'UNVEILED',
        detail: '',
        tag: 'RoyalePaste',
        createdAt: '',
        readTimeInMin: '3',
        link: 'https://www.youtube.com/watch?v=okgq55L95ek&list=PLrxMtPe18EITO-P4BvG9h8ARzGJcRnm8c&index=2'
    },
    {
        id: 5,
        image: 'https://ipfs.io/ipfs/bafybeibteabgokbcgbrjfhlewtjjyvbyihmtnahdkzyczsbccgdvgsmh74/shutterstock_1954895734.png',
        title: 'Prepare this things when you want to \n' +
            'start build your house',
        detail: '',
        tag: 'Lifestyle',
        createdAt: '',
        readTimeInMin: '7',
        link: 'https://www.youtube.com/@gsmgroupofcompanies'
    }
];

export async function getGSMLatestNews() {
    const response = await httpGetRequest('/press-and-medias?populate[image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}, id} = x;
        const {title,image,tag,createdAt,readTimeInMin,link,detail} = attributes;
        return {
            id,
            image: fileUrl(image?.data?.attributes?.url),
            title,
            detail,
            tag,
            createdAt,
            readTimeInMin,
            link
        };
    })
}