import User2 from "../assets/pngs/user2.png";
import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl, itOrEmptyList} from "../../core/services/utils.mjs";

const defaultData = [
    {
        id: 1,
        image: 'https://ipfs.io/ipfs/bafybeigo6e23ste5wjosdymtpj2ouelor663kdowq5pzjpg3how7fcwdty/Screenshot%202023-07-23%20at%2013.20.28.png',
        name: '',
        title: 'Vanesa',
        company: 'Customer',
        description: 'They make it easy to get genuine clothes brands here in Tanzania (Also available in Kenya) with affordable prices. They have both adult and baby clothes for different occasions including sports and gym.\n' +
            'Their shoexpress brand provide unique and durable shoes.'
    },
    {
        id: 2,
        image: User2,
        name: '',
        title: '',
        company: 'Customer',
        description: 'My experience with GALCO Logistics was outstanding. They exemplify the \n' +
            'perfect fusion of professionalism and technology, setting a high standard for logistics services. \n' +
            'The integration of advanced technology not only streamlined the process but  also allowed \n' +
            'for transparent communication and accurate delivery estimates. '
    },
    {
        id: 3,
        image: 'https://ipfs.io/ipfs/bafybeiaxihylkzyxiq2j6v7mlvncbezt6tqhrpel22b24zqyl3wlwx42sy/GALCO%20ICD%20Logo.png',
        name: '',
        title: 'Galco Limited',
        company: 'Commercial Officer',
        description: 'GSM spare parts has been trusted providers for all our trucks spares, oils, and batteries. They provide premium battery brands from Korea and Chinese spare of good quality.\n' +
            'Their proficiency in this field makes our business run safe and smooth.'
    },
    {
        id: 4,
        image: 'https://ipfs.io/ipfs/bafybeidxncspbinuiqt4vlfixu6e3fgkgwdhan2mv2bykk7tzvm7sdyeui/WhatsApp%20Image%202023-07-23%20at%2013.08.27.png',
        name: '',
        title: 'GSM Tanzania',
        company: 'Customer',
        description: 'GSM Coconut supplement my meals giving them formidable smell and taste. Anchor and Bravo yeast are my favourites as they give quick results and retain the bites natural flavour.'
    },
];

export async function getGSMCustomerTestimonies() {
    const response = await httpGetRequest('/testimonies?populate[image][fields]=url');
    const {data = {}} = response ?? {};
    return itOrEmptyList(data).length === 0 ? defaultData : itOrEmptyList(data).map(x => {
        const {attributes = {}, id} = x;
        const {name,title,company,description,image} = attributes;
        return {
            id,
            image: fileUrl(image?.data?.attributes?.url),
            name,
            title,
            company,
            description
        };
    })
}