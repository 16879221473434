import {HeaderNavItem} from "./HeaderNavItem";
import {WhiteSpace} from "./WhiteSpace";
import {GSMLogo} from "./GSMLogo";
import {gsmBlackColor, gsmWhiteColor} from "../services/colors.mjs";
import {Link} from "react-router-dom";
import {getScreenType, SCREEN_WIDTH} from "../services/layout";
import {useState} from "react";
import {AppMobileMenu} from "./AppMobileMenu";
import {MenuIcon} from "./MenuIcon";

const menuWrapperStyle = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}


export function AppHeader() {
    const [showMenu, setShowMenu] = useState(false);
    const isSmallScreen = getScreenType() !== SCREEN_WIDTH.EXPANDED;


    return (
        <>
            <div style={{
                position: 'sticky',
                top: 0,
                width: '100%',
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                flexWrap: 'wrap',
                boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                background: gsmWhiteColor,
                zIndex: 9999999,
            }}>
                {
                    !isSmallScreen && <>
                        <div style={{
                            ...menuWrapperStyle,
                            justifyContent: 'flex-end',
                        }}>
                            <HeaderNavItem link={'/about'} name={'About Us'} color={gsmBlackColor}/>
                            <WhiteSpace width={16}/>
                            <HeaderNavItem link={'/companies'} name={'Our Companies'} color={gsmBlackColor}/>
                        </div>
                    </>
                }
                <WhiteSpace width={16}/>
                <Link style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} to={'/'}>
                    <GSMLogo width={isSmallScreen ? '50px' : '90px'}/>
                </Link>
                <WhiteSpace width={16}/>
                {
                    isSmallScreen && <>
                        <span style={{flex: 1}}/>
                        <MenuIcon onShow={() => setShowMenu(true)}/>
                        <WhiteSpace width={16}/>
                    </>
                }
                {
                    !isSmallScreen && <>
                        <div style={{
                            ...menuWrapperStyle,
                            justifyContent: 'flex-start',
                        }}>
                            <HeaderNavItem link={'/products'} name={'Products & Services'} color={gsmBlackColor}/>
                            <WhiteSpace width={16}/>
                            <HeaderNavItem link={'/contacts'} name={'Contact Us'} color={gsmBlackColor}/>
                        </div>
                    </>
                }
            </div>
            <AppMobileMenu show={showMenu} onHide={() => setShowMenu(false)}/>
        </>
    );
}
