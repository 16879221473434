import {gsmBlackColor, gsmRedColor, gsmWhiteColor} from "../services/colors.mjs";
import {GSMLogo} from "./GSMLogo";
import {WhiteSpace} from "./WhiteSpace";
import {HeaderNavItem} from "./HeaderNavItem";
import {SocialIcons} from "./SocialIcons";

export function AppMobileMenu({show = false, onHide}) {
    return (
        <>
            {
                show && <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: gsmWhiteColor,
                    zIndex: 999999999
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: "row",
                        justifyContent: 'flex-end',
                        padding: 16
                    }}>
                        <div onClick={() => {
                            onHide();
                        }} style={{
                            position: 'relative',
                            width: 48,
                            height: 48,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            cursor: 'pointer'
                        }}>
                            <div style={{
                                background: gsmRedColor,
                                width: 30,
                                height: 4,
                                borderRadius: 2,
                                rotate: '45deg',
                                position: 'absolute'
                            }}/>
                            <div style={{
                                background: gsmRedColor,
                                width: 30,
                                height: 4,
                                borderRadius: 2,
                                rotate: '135deg',
                                position: 'absolute'
                            }}/>
                        </div>
                        {/*<HeadlineMedium>Menu</HeadlineMedium>*/}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 16
                    }}>
                        <GSMLogo width={100}/>
                        <WhiteSpace height={48}/>
                        <HeaderNavItem onPress={onHide} link={'/'} name={'Home'} color={gsmBlackColor}/>
                        <WhiteSpace height={16}/>
                        <HeaderNavItem onPress={onHide} link={'/about'} name={'About Us'} color={gsmBlackColor}/>
                        <WhiteSpace height={16}/>
                        <HeaderNavItem onPress={onHide} link={'/companies'} name={'Our Companies'} color={gsmBlackColor}/>
                        <WhiteSpace height={16}/>
                        <HeaderNavItem onPress={onHide} link={'/products'} name={'Products & Services'}
                                       color={gsmBlackColor}/>
                        <WhiteSpace height={16}/>
                        <HeaderNavItem onPress={onHide} link={'/contacts'} name={'Contact Us'} color={gsmBlackColor}/>
                        <WhiteSpace height={48}/>
                        <SocialIcons color={gsmRedColor}/>
                    </div>
                </div>
            }
        </>
    );
}
