import {getScreenType, SCREEN_WIDTH} from "../services/layout";

export function Center({children}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: getScreenType() === SCREEN_WIDTH.COMPACT ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }}>
            {children}
        </div>
    )
}