import {httpGetRequest} from "../../core/services/network.mjs";
import {fileUrl} from "../../core/services/utils.mjs";


export async function getGSMAdvert() {
    const response = await httpGetRequest('/advert?populate[image][fields]=url');
    const {data = {}} = response ?? {};
    const {attributes = {}} = data;
    const {image} = attributes;
    return {
        image: fileUrl(image?.data?.attributes?.url),
    };
}