import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Container from "../../core/components/Container";
import {HeadItem} from "../components/HeadItem";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import React, {useEffect, useState} from "react";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {getRetailCompanies} from "../services/retails";
import {itOrEmptyList, prepareSplitArrayTo} from "../../core/services/utils.mjs";
import {LabelLarge} from "../../core/components/LabelLarge";
import {useScrollUp} from "../../core/hooks/scrollUp";
import {Link} from "react-router-dom";

function Brands({brands, showName = false}) {
    return (
        <>
            <WhiteSpace height={24}/>
            {
                itOrEmptyList(brands).map((item, index) => {
                    return (
                        <div key={`${index}_brand_row`}
                             style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
                            {
                                itOrEmptyList(item).map((brand, brandIndex) => {
                                    return (
                                        <div style={{
                                            flexGrow: 1,
                                            padding: 16,
                                            margin: 5,
                                            borderRadius: '8px',
                                            display: "flex",
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: brand?.bg ?? '#ffffff'
                                        }} key={`${brandIndex}_bi_in`}>
                                            <img
                                                style={{width: brand?.width ?? 168, height: brand?.height ?? undefined}}
                                                src={brand?.image} alt={brand?.name}/>
                                            {
                                                showName && <>
                                                    <WhiteSpace height={8}/>
                                                    <LabelLarge>{brand?.name}</LabelLarge>
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </>
    )
}

// function Fashion() {
//     const [data, setData] = useState();
//     const [brands, setBrands] = useState([]);
//
//     useEffect(() => {
//         getGSMRetailFashion().then(setData).catch(() => {
//         });
//     }, []);
//
//     useEffect(() => {
//         if (data) {
//             const splitBrands = prepareSplitArrayTo(3);
//             setBrands(splitBrands(data?.brands));
//         }
//     }, [data]);
//
//     return (
//         <>
//             <HeadItem logo={data?.logo} detail={data?.detail}/>
//             <div style={{height: 350, width: '100%'}}>
//                 <BackgroundImage title={data?.title} source={data?.image}/>
//             </div>
//             <Brands brands={brands}/>
//         </>
//     )
// }

function RetailItem({data,showBrandName=false}) {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        if (data) {
            const splitBrands = prepareSplitArrayTo(3);
            setBrands(splitBrands(data?.brands));
        }
    }, [data]);


    return (
        <>
            {data?.logo && data?.detail && <HeadItem logo={data?.logo} detail={data?.detail}/>}
            {data?.image && <div style={{height: 350, width: '100%'}}>
                <BackgroundImage title={data?.title} source={data?.image}/>
            </div>}
            {itOrEmptyList(brands).length > 0 && <Brands showName={showBrandName} brands={brands}/>}
        </>
    )
}

// function Showrooms() {
//     const [data, setData] = useState();
//     const [brands, setBrands] = useState([]);
//
//     useEffect(() => {
//         getGSMRetailShowroom().then(setData).catch(() => {
//         });
//     }, []);
//
//     useEffect(() => {
//         if (data) {
//             const splitBrands = prepareSplitArrayTo(4);
//             setBrands(splitBrands(data?.brands));
//         }
//     }, [data]);
//
//     return (
//         <>
//             <HeadItem logo={data?.logo} detail={data?.detail}/>
//             <Brands showName brands={brands}/>
//         </>
//     )
// }

// function Furniture() {
//     const [data, setData] = useState();
//     const [brands, setBrands] = useState([]);
//
//     useEffect(() => {
//         getGSMRetailFurniture().then(setData).catch(() => {
//         });
//     }, []);
//
//     useEffect(() => {
//         if (data) {
//             const splitBrands = prepareSplitArrayTo(3);
//             setBrands(splitBrands(data?.brands));
//         }
//     }, [data]);
//
//     return (
//         <>
//             <div style={{height: 350, width: '100%'}}>
//                 <BackgroundImage title={data?.title} source={data?.image}/>
//             </div>
//             <Brands brands={brands}/>
//         </>
//     )
// }

export function RetailPage() {
    const [data, setData] = useState([]);

    useEffect(() => {
        getRetailCompanies().then(value => {
            setData(value);
        }).catch(() => {
        });
    }, []);
    useScrollUp();
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <WhiteSpace height={48}/>
            <Container>
                {
                    itOrEmptyList(data).map(item => {
                        return (
                            <div key={item?.key}>
                                <Link to={'/companies/retail/' + `${item?.id ?? ''}`.split('/').pop()}>
                                    <RetailItem showBrandName={`${item?.title}`.toLowerCase().includes('furniture')} data={item}/>
                                </Link>
                                <WhiteSpace height={24}/>
                            </div>
                        );
                    })
                }
            </Container>
            <NewsUpdates/>
            <Footer/>
        </div>
    );
}