import {AppHeader} from "../../core/components/AppHeader";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import Container from "../../core/components/Container";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import React, {useEffect, useState} from "react";
import {useScrollUp} from "../../core/hooks/scrollUp";
import {getGSMRetailFashionContents} from "../services/retails_fashion";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {itOrEmptyList, justList} from "../../core/services/utils.mjs";
import {BodyLarge} from "../../core/components/BodyLarge";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {ReactComponent as FashionIcon} from "../assets/fashion_icon.svg";
import {BodyMedium} from "../../core/components/BodyMedium";


function Head({image, detail, logo}) {
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT
    return (
        <>
            <WhiteSpace height={24}/>
            <div style={{position: 'relative'}}>
                <div style={{width: '100%', height: isSmallScreen ? 200 : 400}}>
                    <BackgroundImage
                        source={image}
                        style={{width: '100%', height: isSmallScreen ? 150 : 350}}>
                        <div/>
                    </BackgroundImage>
                </div>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: "nowrap"
                }}>
                    {!isSmallScreen && <div style={{flex: 1}}/>}
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{height: 150, width: 150, display: "flex", textAlign: 'center'}}>
                            <img alt={'logo'} src={logo}
                                 style={{maxHeight: '100%', maxWidth: '100%', borderRadius: 8}}/>
                        </div>
                    </div>
                    {!isSmallScreen && <div
                        style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8}}>
                        <BodyLarge color={gsmWhiteColor}>
                            {detail}
                        </BodyLarge>
                    </div>}
                </div>
            </div>
        </>
    );
}

function Body({location, name}) {
    const [selected, setSelected] = useState(() => itOrEmptyList(location)[0]);
    const isSmallScreen = getScreenType() === SCREEN_WIDTH.COMPACT;
    return (
        <div style={{display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', flexWrap: 'nowrap'}}>
            <div style={isSmallScreen ? {} : {flex: 0.3}}>
                <WhiteSpace height={16}/>
                <div style={centerRowStyle}>
                    <FashionIcon style={{width: 48, height: 48}}/>
                    <WhiteSpace width={8}/>
                    <BodyLarge color={gsmRedColor}>{name} Location</BodyLarge>
                </div>
                <WhiteSpace height={16}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {
                        itOrEmptyList(location).map((item, index) => {
                            return (
                                <div onClick={() => setSelected(item)} style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    padding: 8,
                                    cursor: 'pointer'
                                }} key={`${index}_l_h_iiuoyougj`}>
                                    <BodyMedium>{item?.name}</BodyMedium>
                                    <WhiteSpace height={8}/>
                                    <div style={{
                                        backgroundColor: selected?.name === item?.name ? gsmRedColor : undefined,
                                        height: 2,
                                        width: '100%'
                                    }}/>
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    {justList(selected?.detail).map(k=>{
                        return (
                            <div key={JSON.stringify(k)}>
                                <BodyLarge>{k}</BodyLarge>
                            </div>
                        )
                    })}
                </div>
            </div>
            <WhiteSpace height={16} width={16}/>
            <div style={isSmallScreen ? {height: 300, width: '100%'} : {flex: 0.7, minHeight: 300}}>
                <BackgroundImage style={{width: '100%', height: isSmallScreen ? 300 : '100%'}}
                                 source={selected?.image}/>
            </div>
        </div>
    );
}


export function RetailFashionPage() {
    const [data, setData] = useState([]);
    useScrollUp();
    useEffect(() => {
        getGSMRetailFashionContents().then(setData).catch(() => {
        })
    }, []);
    return (
        <div style={{width: '100%'}}>
            <AppHeader/>
            <WhiteSpace height={16}/>
            <Container>
                {
                    itOrEmptyList(data).map((item, index) => {
                        return <div key={`${index}_r_f_i`}>
                            <Head logo={item?.logo} image={item?.image} detail={item?.detail}/>
                            <WhiteSpace height={16}/>
                            <Body location={item?.locations} name={item?.name}/>
                            <WhiteSpace height={48}/>
                        </div>
                    })
                }
            </Container>
            <NewsUpdates/>
            <Footer/>
        </div>
    );
}

const centerRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
};