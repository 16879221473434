import {AppHeader} from "../../core/components/AppHeader";
import NewsUpdates from "../../core/components/NewsUpdates";
import Footer from "../../core/components/Footer";
import React, {useEffect, useState} from "react";
import Container from "../../core/components/Container";
import {WhiteSpace} from "../../core/components/WhiteSpace";
import {gsmRedColor, gsmWhiteColor} from "../../core/services/colors.mjs";
import {getGSMLogisticCompanyServices} from "../services/logistics";
import {itOrEmptyList, prepareSplitArrayTo} from "../../core/services/utils.mjs";
import {BackgroundImage} from "../../core/components/BackgroundImage";
import {BodyLarge} from "../../core/components/BodyLarge";
import {HeadlineSmall} from "../../core/components/HeadlineSmall";
import {BodyMedium} from "../../core/components/BodyMedium";
import {getScreenType, SCREEN_WIDTH} from "../../core/services/layout";
import {useScrollUp} from "../../core/hooks/scrollUp";

function BodyItem({logo, image, name, detail, services = [], action}) {
    const [chunkedServices, setChunkedServices] = useState([]);

    useEffect(() => {
        if (itOrEmptyList(services).length > 0) {
            const size = getScreenType() === SCREEN_WIDTH.COMPACT ? 2 : 3;
            const chunkServices = prepareSplitArrayTo(size);
            const data = chunkServices([...services]);
            setChunkedServices(data?.reduce((a, b) => {
                if (itOrEmptyList(b).length === size) {
                    return [...a, b];
                } else {
                    const s = itOrEmptyList(b).length;
                    const rm = size - s;
                    let _b = [...itOrEmptyList(b)];
                    for (let i = 0; i < rm; i++) {
                        _b.push({id: -1});
                    }
                    return [...a, _b];
                }
            }, []));
        }
    }, [services])
    return (
        <>
            <div style={{
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
                borderRadius: 10,
                padding: 16,
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div style={{width: 'auto', minHeight: 100}}>
                        <img alt={'logo'} src={logo} height={110} width={'auto'} style={{borderRadius: 5, padding: 3}}/>
                    </div>
                    <WhiteSpace width={16}/>
                    {/*<div style={{height: 30, width: '100%', backgroundColor: gsmRedColor, borderRadius: 5}}/>*/}
                </div>
                <div style={{width: '100%', height: 250}}>
                    <BackgroundImage title={name} style={{width: '100%', height: 250}} source={image}/>
                </div>
                <WhiteSpace height={16}/>
                <BodyLarge>
                    {detail}
                </BodyLarge>
                <WhiteSpace height={16}/>
                {
                    itOrEmptyList(services).length > 0 && <>
                        <WhiteSpace height={16}/>
                        <HeadlineSmall
                            color={gsmRedColor}
                            fontWeight={900}>
                            OUR SERVICES
                        </HeadlineSmall>
                        <WhiteSpace height={8}/>
                        <div style={{width: '40%', backgroundColor: gsmRedColor, height: 5, borderRadius: 2}}/>
                        <WhiteSpace height={24}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            // justifyContent: 'space-between'
                        }}>
                            {
                                chunkedServices?.map((chunk, index) => {
                                    return (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            flexWrap: 'wrap',
                                        }} key={`${index}_out_c`}>
                                            {
                                                itOrEmptyList(chunk).map((item, si) => {
                                                    return (
                                                        <div key={`${si}_svg_services_logistic`}
                                                             style={{
                                                                 flex: 1,
                                                                 padding: 8
                                                             }}>
                                                            <img alt={item?.name} src={item?.svg} width="auto" height="35"/>
                                                            <WhiteSpace width={8}/>
                                                            <BodyMedium>{item?.name}</BodyMedium>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <WhiteSpace height={16}/>
                    </>
                }
                <div style={{
                    backgroundColor: gsmRedColor,
                    color: gsmWhiteColor,
                    padding: 16,
                    borderRadius: 4,
                    display: 'inline-block'
                }}>
                    <BodyLarge>
                        <a href={action?.link} color={gsmWhiteColor} style={{
                            color: gsmWhiteColor,
                            textDecoration: gsmWhiteColor
                        }}>
                            {action?.name}
                        </a>
                    </BodyLarge>
                </div>
            </div>
            <WhiteSpace height={48}/>
        </>
    );
}

export function LogisticPage() {
    const [services, setServices] = useState([]);
    useEffect(() => {
        getGSMLogisticCompanyServices().then(setServices).catch(() => {
        });
    }, []);
    useScrollUp();
    return (
        <>
            <AppHeader/>
            <WhiteSpace height={48}/>
            <Container>
                {
                    itOrEmptyList(services).map((item, index) => {
                        return (
                            <div key={`${index}_logistic_product`}>
                                <BodyItem
                                    services={item?.services}
                                    detail={item?.detail}
                                    name={item?.name}
                                    image={item?.image}
                                    action={item?.action}
                                    logo={item?.logo}/>
                            </div>
                        )
                    })
                }
            </Container>
            <NewsUpdates/>
            <Footer/>
        </>
    )
}
